import { ENV_URLS } from '../../helpers/Constants'
import {
  externalAzureFunctionHttpTriggerBase,
  externalHttpCACBase,
  externalHttpCIBase,
  externalHttpCoreViewBase,
  externalHttpDIRECTROUTINGBase,
  externalHttpGENERICFUNCTIONALITYBase,
  externalHttpMAXCSBase,
  externalHttpSIPAnywhereBase,
  httpBase,
} from './httpBaseUtil'

export function fetch(endpoint, params) {
  return httpBase().get(`/${endpoint}`, { params })
}

export function store(endpoint, data) {
  return httpBase().post(`/${endpoint}`, data)
}

export function update(endpoint, data) {
  return httpBase().put(`/${endpoint}`, data)
}
export function patch(endpoint, data) {
  return httpBase('patch').patch(`/${endpoint}`, data)
}

export function destroy(endpoint) {
  return httpBase().delete(`/${endpoint}`)
}

export function download(endpoint, params) {
  return httpBase(true).get(`/${endpoint}`, { params })
}
export function downloadPDF(endpoint, params) {
  return httpBase('', true).get(`/${endpoint}`, { params })
}

export function externalCIFetch(endpoint, params, customerId) {
  return externalHttpCIBase(customerId).get(`/${endpoint}`, { params })
}

export function externalCIStore(endpoint, data, customerId) {
  return externalHttpCIBase(customerId).post(`/${endpoint}`, data)
}

export function externalCIUpdate(endpoint, data, params, customerId) {
  return externalHttpCIBase(customerId).put(`/${endpoint}`, data, { params })
}

export function externalCIDestroy(endpoint, customerId) {
  return externalHttpCIBase().delete(`/${endpoint}`)
}

export function externalCACFetch(endpoint, params, customerId) {
  return externalHttpCACBase(customerId).get(`/${endpoint}`, { params })
}

export function externalCACStore(endpoint, data, customerId) {
  return externalHttpCACBase(customerId).post(`/${endpoint}`, data)
}

export function externalCACUpdate(endpoint, data, params, customerId) {
  return externalHttpCACBase(customerId).put(`/${endpoint}`, data, { params })
}

export function externalCACDestroy(endpoint, customerId) {
  return externalHttpCACBase().delete(`/${endpoint}`)
}

// coreview
export function externalCoreViewFetch(endpoint, params, customerId) {
  return externalHttpCoreViewBase(customerId).get(`/${endpoint}`, { params })
}

export function externalCoreViewStore(endpoint, data, customerId) {
  return externalHttpCoreViewBase(customerId).post(`/${endpoint}`, data)
}

export function externalCoreViewPatch(endpoint, data, customerId) {
  return externalHttpCoreViewBase(customerId, 'patch').patch(
    `/${endpoint}`,
    data
  )
}
export function externalCoreViewDownload(endpoint, data, customerId) {
  return externalHttpCoreViewBase(customerId).post(`/${endpoint}`, data, {
    responseType: 'blob',
  })
}

export function externalCoreViewUpdate(endpoint, data, customerId) {
  return externalHttpCoreViewBase(customerId).put(`/${endpoint}`, data)
}

export function externalCoreViewDestroy(endpoint, customerId) {
  return externalHttpCoreViewBase(customerId).delete(`/${endpoint}`)
}

export function externalCoreViewDestroyWithBody(endpoint, customerId, params) {
  return externalHttpCoreViewBase(customerId, true).delete(`/${endpoint}`, {
    data: params,
  })
}

// maxcs
export function externalMAXCSFetch(endpoint, params, customerId) {
  return externalHttpMAXCSBase(customerId).get(`/${endpoint}`, { params })
}

export function externalMAXCSStore(endpoint, data, customerId) {
  return externalHttpMAXCSBase(customerId).post(`/${endpoint}`, data)
}
export function externalMAXCSPatch(endpoint, data, customerId) {
  return externalHttpMAXCSBase(customerId, 'patch').patch(`/${endpoint}`, data)
}
export function externalMAXCSDownload(endpoint, data, customerId) {
  return externalHttpMAXCSBase(customerId).post(`/${endpoint}`, data, {
    responseType: 'blob',
  })
}

export function externalMAXCSUpdate(endpoint, data, customerId) {
  return externalHttpMAXCSBase(customerId).put(`/${endpoint}`, data)
}

export function externalMAXCSDestroy(endpoint, customerId) {
  return externalHttpMAXCSBase().delete(`/${endpoint}`)
}
//Generic Functionality
export function externalGENRICFUNCTIONALITYROUTINGStore(endpoint,customerId,productCode, data ) {
  return externalHttpGENERICFUNCTIONALITYBase(customerId,productCode).post(`/${endpoint}`, data)
}
export function externalGENRICFUNCTIONALITYROUTINGFetch(endpoint, params, customerId, productCode) {
  return externalHttpGENERICFUNCTIONALITYBase(customerId,productCode).get(`/${endpoint}`, {
    params,
  })
}
export function externalGENRICFUNCTIONALITYROUTINGPatch(endpoint, data, customerId, productCode) {
  return externalHttpGENERICFUNCTIONALITYBase(customerId, productCode, 'patch').patch(
    `/${endpoint}`,
    data
  )
}
export function externalGENRICFUNCTIONALITYROUTINGDestroy(endpoint, customerId, productCode) {
  return externalHttpGENERICFUNCTIONALITYBase(customerId, productCode).delete(`/${endpoint}`)
}
export function externalGENRICFUNCTIONALITYROUTINGUpdate(endpoint, data, customerId, productCode) {
  return externalHttpGENERICFUNCTIONALITYBase(customerId, productCode).put(`/${endpoint}`, data)
}
//Teams Phone Solutions
export function externalDIRECTROUTINGFetch(endpoint, params, customerId) {
  return externalHttpDIRECTROUTINGBase(customerId).get(`/${endpoint}`, {
    params,
  })
}

export function externalDIRECTROUTINGStore(endpoint, data, customerId) {
  return externalHttpDIRECTROUTINGBase(customerId).post(`/${endpoint}`, data)
}

export function externalDIRECTROUTINGPatch(endpoint, data, customerId) {
  return externalHttpDIRECTROUTINGBase(customerId, 'patch').patch(
    `/${endpoint}`,
    data
  )
}
export function externalDIRECTROUTINGDownload(endpoint, data, customerId) {
  return externalHttpDIRECTROUTINGBase(customerId).post(`/${endpoint}`, data, {
    responseType: 'blob',
  })
}
export function externalDIRECTROUTINGDownloadExcel(
  endpoint,
  params,
  customerId
) {
  return externalHttpDIRECTROUTINGBase(customerId).get(`/${endpoint}`, {
    params: params,
    responseType: 'blob',
  })
}

export function externalDIRECTROUTINGUpdate(endpoint, data, customerId) {
  return externalHttpDIRECTROUTINGBase(customerId).put(`/${endpoint}`, data)
}

export function externalDIRECTROUTINGDestroy(endpoint, customerId) {
  return externalHttpDIRECTROUTINGBase(customerId).delete(`/${endpoint}`)
}

//Sip Trunking
export function externalSipAnywhereFetch(endpoint, customerId) {
  return externalHttpSIPAnywhereBase(customerId).get(`/${endpoint}`)
}