import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import Dropdown from '../../../../../../../common/components/dropdown/Dropdown'
import NumberField from '../../../../../../../common/components/numberfield/Numberfield'
import {
  serviceSliceState,
  setTimeZones,
  setCallerIds,
  setVoiceMailTranscription,
} from '../../../../../../../store/slice/serviceSlice'
import { useSelector } from 'react-redux'
import {
  executeWithTryCatch,
  normalizeApiResponse,
  requiredLabel,
} from '../../../../../../../helpers/HelperFunctions'
import {
  getAvailabeActiveDIDTFNNumbers,
  getTimeZones,
  getVoiceMailTranscription,
} from '../../../../../../../api/services/MaxCloudAPI'
import { useCommonRedux } from '../../../../../../../store/middlewares/CommonRedux'
import { useToastContext } from '../../../../../../../App'
import jwt_decode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'

const BasicAndDefault = ({
  formData,
  handleChange,
  handleToggle,
  handleChangeOptions,
  errors,
  setFormData,
  formMode,
}) => {
  const serviceState = useSelector(serviceSliceState)
  const { toast } = useToastContext()
  const { dispatch } = useCommonRedux()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    executeWithTryCatch(getBasicDetails, handleGetBasicDetailsError)
    executeWithTryCatch(getCallerId, handleGetBasicDetailsError)
  }, [])
  const [searchParams] = useSearchParams()

  const getCompanyId = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      return companyId
    }
  }

  const getBasicDetails = async () => {
    const response = await getTimeZones(getCompanyId())
    if (response) {
      const timeZoneOptions = (response?.data?.Result ?? []).map((e) => ({
        name: e.TimeZone,
        value: e.Id,
      }))
      dispatch(setTimeZones(timeZoneOptions))
      const responseVoiceMailTranscription = await getVoiceMailTranscription(
        getCompanyId()
      )
      if (responseVoiceMailTranscription) {
        const voiceMailTranscriptionOptions = (
          responseVoiceMailTranscription?.data?.Result ?? []
        ).map((e) => ({
          name: e.SDCUIVoiceMailTranscriptionValue,
          value: e.NetSapAPIVoiceMailTranscriptionValue,
        }))
        dispatch(setVoiceMailTranscription(voiceMailTranscriptionOptions))
      }
      setLoading(false)
    }
  }

  const getCallerId = async () => {
    const response = await getAvailabeActiveDIDTFNNumbers({}, getCompanyId())
    if (response) {
      const callerIdOptions = (response?.data?.Result ?? []).map((e) => ({
        name: e,
        value: e,
      }))
      callerIdOptions.push({ name: '0000000000', value: '0000000000' })
      dispatch(setCallerIds(callerIdOptions))
    }
  }

  const handleGetBasicDetailsError = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
    setLoading(false)
  }

  const VMTranscriptionOrder =
    serviceState?.summaryDetails?.RevioOrderInfo?.find(
      (order) => order.Description === 'MaxCloud UC2 VM Transcription'
    )

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Name', false)}</Typography>
            <TextField
              name="domain"
              value={formData.domain}
              disabled
              fullWidth
              size={'small'}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Reseller', false)}</Typography>
            <TextField
              name="reseller"
              value={formData.reseller}
              disabled
              fullWidth
              size={'small'}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Status', false)}</Typography>
            <Select
              name="is-domain-locked"
              value={formData['is-domain-locked']}
              sx={{ height: '40px' }}
              disabled
              onChange={handleChange}
            >
              {[
                { name: 'Active', value: 'no' },
                { name: 'Locked', value: 'yes' },
              ].map((data) => (
                <MenuItem sx={{ minHeight: 40 }} value={data.value}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <Stack direction={'column'} sx={{ paddingTop: '20px' }}>
        <Typography variant="h5">Defaults</Typography>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Time Zone', true)}</Typography>
            <Dropdown
              name="time-zone"
              options={loading ? [] : serviceState?.timeZones || []}
              getOptionLabel={(option) =>
                typeof option === 'string' ? option : option?.name || ''
              }
              isOptionEqualToValue={(option, value) =>
                typeof option === 'string'
                  ? option === value
                  : option?.name === value
              }
              fullWidth
              size="small"
              value={formData?.['time-zone'] || ''}
              onChange={(event, newValue) =>
                handleChange({
                  target: {
                    name: 'time-zone',
                    value:
                      typeof newValue === 'string'
                        ? newValue
                        : newValue?.name || '',
                  },
                })
              }
              disabled={loading}
              error={!!errors?.['time-zone']}
              errorMessage={errors?.['time-zone']}
              placeHolder={loading ? 'Loading Timezones...' : 'Select Timezone'}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Area Code', true)}</Typography>
            <NumberField
              name="area-code"
              value={formData?.['area-code']}
              onChange={handleChange}
              fullWidth
              placeholder="321"
              maxDigits={3}
              size={'small'}
              errorMessage={'Minimum 3 digits required'}
              error={!!errors?.['area-code']}
              helperText={errors?.['area-code'] || ''}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Caller Name', true)}</Typography>
            <TextField
              error={!!errors?.['caller-id-name']}
              helperText={errors?.['caller-id-name'] || ''}
              placeholder="Enter Caller Name"
              name="caller-id-name"
              value={formData?.['caller-id-name']}
              fullWidth
              size="small"
              onChange={handleChange}
            />
            <FormHelperText>The name sent to emergency services</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Caller ID', true)}</Typography>
            {formMode === 'edit' ? (
              <Dropdown
                options={serviceState?.callerIds}
                getOptionLabel={(opt) => opt.name}
                name="caller-id-number"
                value={{
                  name:
                    formData?.['caller-id-number'] === 0
                      ? '0000000000'
                      : formData?.['caller-id-number'],
                  value:
                    formData?.['caller-id-number'] === 0
                      ? '0000000000'
                      : formData?.['caller-id-number'],
                }}
                onChange={(e, value, reason) => {
                  handleChange({
                    target: {
                      name: 'caller-id-number',
                      value: value.value,
                    },
                  })
                  handleChange({
                    target: {
                      name: 'caller-id-number-emergency',
                      value: value.value,
                    },
                  })
                }}
                fullWidth
                maxDigits={10}
                size={'small'}
                errorMessage={'Minimum 10 digits required'}
                error={!!errors?.['caller-id-number']}
                helperText={errors?.['caller-id-number'] || ''}
              />
            ) : (
              <Stack direction={'column'} justifyContent={'center'} spacing={1}>
                <NumberField
                  name="caller-id-number"
                  value={formData?.['caller-id-number']}
                  onChange={(e) => {
                    handleChange(e)
                    handleChange({
                      target: {
                        name: 'caller-id-number-emergency',
                        value: e.target.value,
                      },
                    })
                  }}
                  disabled={formData?.['caller-id-number'] === '0000000000'}
                  fullWidth
                  maxDigits={10}
                  size={'small'}
                  errorMessage={'Minimum 10 digits required'}
                  error={!!errors?.['caller-id-number']}
                  helperText={errors?.['caller-id-number'] || ''}
                />
                {formMode !== 'edit' && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          formData?.['caller-id-number'] === '0000000000'
                        }
                        onChange={(e) => {
                          const newValue = e.target.checked ? '0000000000' : ''
                          setFormData((prevData) => ({
                            ...prevData,
                            'caller-id-number': newValue,
                            'caller-id-number-emergency': newValue,
                          }))
                        }}
                      />
                    }
                    label={
                      <FormHelperText>
                        Select this if you do not have a number. You can update
                        this once the number is ordered.
                      </FormHelperText>
                    }
                  />
                )}
              </Stack>
            )}
          </FormControl>
        </Grid>
        {/* <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>
              {requiredLabel('Extension digit length', true)}
            </Typography>
            <RadioGroup
              row
              name="extensionDigits"
              value={formData?.extensionDigits}
              onChange={handleChange}
              sx={{ paddingLeft: '15px' }}
              error={!!errors?.['extensionDigits']}
            >
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="3 digits"
                disabled={
                  !!formData?.extensionDigits &&
                  serviceState?.domainData?.extensionDigits != undefined
                }
              />
              <FormControlLabel
                value={4}
                control={<Radio />}
                label="4 digits"
                disabled={
                  !!formData?.extensionDigits &&
                  serviceState?.domainData?.extensionDigits != undefined
                }
              />
            </RadioGroup>
            {errors?.extensionDigits && (
              <FormHelperText error>{errors.extensionDigits}</FormHelperText>
            )}
            <FormHelperText>
              This value cannot be edited later. For example, 3-digit
              extensions: 199, 4-digit extensions: 1999.
            </FormHelperText>
          </FormControl>
        </Grid> */}
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Extension length', true)}</Typography>
            <RadioGroup
              row
              name="extensionDigits"
              value={formData?.extensionDigits}
              onChange={handleChange}
              sx={{ paddingLeft: '15px' }}
              error={!!errors?.['extensionDigits']}
            >
              <FormControlLabel
                value={3}
                control={<Radio />}
                label="3 digits"
                disabled={
                  serviceState?.domainData != null &&
                  serviceState?.domainData?.extensionDigits != 0
                }
              />
              <FormControlLabel
                value={4}
                control={<Radio />}
                label="4 digits"
                disabled={
                  serviceState?.domainData != null &&
                  serviceState?.domainData?.extensionDigits != 0
                }
              />
            </RadioGroup>
            {errors?.extensionDigits && (
              <FormHelperText error>{errors.extensionDigits}</FormHelperText>
            )}
            <FormHelperText>
              This value cannot be edited later. For example, 3-digit
              extensions: 199, 4-digit extensions: 1999.
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>
              {requiredLabel('Voice Mail Transcription', false)}
            </Typography>
            <Select
              name="voicemail-transcription-enabled"
              value={formData?.['voicemail-transcription-enabled']}
              sx={{ height: '40px' }}
              onChange={handleChange}
              disabled={VMTranscriptionOrder && VMTranscriptionOrder != 'no'}
            >
              {serviceState?.voiceMailTranscription ? (
                serviceState?.voiceMailTranscription?.map((data) => (
                  <MenuItem
                    key={data.value}
                    sx={{ minHeight: 40 }}
                    value={data.value}
                  >
                    {data.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Loading...</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default BasicAndDefault
