import { store, fetch, update, destroy } from '../utils/httpUtil'

// Master Details
export const masterDetailsApi = () => fetch(`Master/details`)
export const masterDetailsPutApi = (data, locationRoute) =>
  update(`${locationRoute}`, data)

export const masterUserListApi = (id, locationRoute) =>
  fetch(`${locationRoute}/${id}/user/all`)
export const masterUserDetailApi = (id, locationRoute) =>
  fetch(`${locationRoute}/user/${id}`)
export const masterUserRoleByIdApi = (id, locationRoute) =>
  fetch(`${locationRoute}/user/${id}/roles`)
export const masterUserPostApi = (data, locationRoute) =>
  store(`${locationRoute}/user`, data)
export const masterUserPutApi = (data, locationRoute) =>
  update(`${locationRoute}/user`, data)
export const masterRoleAllByIdApi = (id, locationRoute) =>
  fetch(`${locationRoute}/${id}/role/all`)
export const masterUserDeleteApi = (id, locationRoute) =>
  destroy(`${locationRoute}/user/${id}`)
export const masterAdminResetPasswordApi = (data, locationRoute) =>
  store(`${locationRoute}/adminresetpassword`, data)
export const masterResetDraftCompanyPasswordApi = (data, locationRoute) =>
  update(`${locationRoute}/user/resetdraftcompanyuserpassword`, data)

// ${locationRoute} Role api
export const masterRoleListApi = (id, locationRoute) =>
  fetch(`${locationRoute}/${id}/role/all`)
export const masterRoleDetailApi = (id, locationRoute) =>
  fetch(`${locationRoute}/role/${id}`)
export const masterRoleByIdPrivilegesApi = (id, locationRoute) =>
  fetch(`${locationRoute}/role/${id}/privileges`)
export const masterPrivilegesApi = (locationRoute) =>
  fetch(`${locationRoute}/privileges`)
export const masterRolePostApi = (data, locationRoute) =>
  store(`${locationRoute}/role`, data)
export const masterRolePutApi = (data, locationRoute) =>
  update(`${locationRoute}/role`, data)
export const masterRoleDeleteApi = (id, locationRoute) =>
  destroy(`${locationRoute}/role/${id}`)

//Master Contact API
export const masterContactAllApi = (id, locationRoute) =>
  fetch(`${locationRoute}/${id}/contact/all`)
export const masterPostContactApi = (data, locationRoute) =>
  store(`${locationRoute}/contact`, data)
// export const masterContactDetailsByIdApi = (id) => fetch(`Master/contact/${id}`);
// export const masterPutContactApi = (data) => update(`Master/contact`);

//Master Products API
export const masterProductsAllApi = (id, locationRoute) =>
  fetch(`${locationRoute}/product/all`)
export const masterProductsDetailsApi = (id, locationRoute) =>
  fetch(`${locationRoute}/product/${id}`)
export const masterProductsPostApi = (data, locationRoute) =>
  store(`${locationRoute}/product`, data)

// Master Address all
export const masterAddressAllApi = (id, locationRoute) =>
  fetch(`${locationRoute}/${id}/address/all`)
export const masterPostAddressApi = (data, locationRoute) =>
  store(`${locationRoute}/address`, data)

// configurationTab API
export const masterConfigurationTabGetApi = (id, locationRoute) =>
  fetch(`${locationRoute}/${id}/configuration`)
export const masterConfigurationTabPostApi = (data, locationRoute) =>
  store(`${locationRoute}/configuration/msrp`, data)

// Notification Template API
export const masterNotificationTemplatePostApi = (data) =>
  store(`master/notificationtemplate/submit`, data)
export const masterNotificationTemplateDraftPostApi = (data) =>
  store(`master/notificationtemplate/draft`, data)
export const masterNotificationTemplateGetListApi = () =>
  fetch(`master/notificationtemplate/all`)
export const masterNotificationReceipientsByIdApi = (id) =>
  fetch(`master/notificationtemplate/${id}/receipient`)
export const masterNotificationTemplateUpdateApi = (data) =>
  update(`master/notificationtemplate`, data)
export const masterNotificationTemplateCancelApi = (templateId) =>
  destroy(`master/notificationtemplate/${templateId}`)
export const masterNotificationTemplateTestApi = (data) =>
  store(`master/notificationtemplate/sendtestemail`, data)

// Notification List User API
export const masterUserNotificationGetApi = (id, locationRoute) =>
  fetch(`Notification/all`)
export const masterUserNotificationGetByIdApi = (id, locationRoute) =>
  update(`Notification/Viewed/${id}`)


// Company Configuration and setup

export const api_generateM365License = (data) =>
  store(`Customer/configuration/AssignM365LicenseToTeamsUser`, data)
