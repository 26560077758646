//GET
import { PRODUCT_CODES } from '../../helpers/Constants'
import {
  externalCoreViewFetch,
  externalCoreViewStore,
  externalMAXCSFetch,
  externalGENRICFUNCTIONALITYROUTINGFetch,
  externalGENRICFUNCTIONALITYROUTINGStore,
  externalGENRICFUNCTIONALITYROUTINGPatch,
  externalGENRICFUNCTIONALITYROUTINGUpdate,
  externalCoreViewUpdate,
  externalCoreViewDestroy,
  externalCoreViewDestroyWithBody,
} from '../utils/httpUtil'

export const api_getMaxFaxList = async (companyId, didNumber = 0) => {
  return await externalCoreViewFetch(
    `MaxFaxOrderInfo?companyId=${companyId}&didNumber=${didNumber}`,
    '',
    companyId
  )
}
export const orderingServiceTeamsBundle = (params, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `bundle/all`,
    params,
    customerId,
    PRODUCT_CODES.maxCloudUC
  ) //moved

export const getAvailabeActiveDIDTFNNumbers = (params, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `DidNumber/GetAvailabeActiveDIDTFNNumbersAsync`,
    params,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )
export const api_MaxUCloudGetAltigenVoicePolicies = (customerId, params) =>
  externalMAXCSFetch(
    `NumberAssignment/AltigenVoicePolicies`,
    params,
    customerId
  )
export const orderingServiceTeamsBundleInfoGet = (params, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `TeamsBundleInfo`,
    params,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )
export const orderingServiceTollFreesAvailables = (params, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `DidNumber/available/tollfrees`,
    params,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )
export const orderingServiceDIDNumberGet = (params, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `DidNumber`,
    params,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )
export const orderingServiceCoreViewDIDFeaturesGet = (params, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `DidNumber/features`,
    params,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )
//POST
export const api_MaxCloudNewNumberRequest = (data, customerId, productCode) =>
  externalGENRICFUNCTIONALITYROUTINGStore(
    `NewNumberRequest`,
    customerId,
    productCode,
    data
  )

export const api_MaxCloudDisconnectNumbers = async (companyId, requestBody) => {
  return await externalCoreViewStore(`RemoveNumber`, requestBody, companyId)
}
export const orderingServiceRemoveInfoDelete = (body, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGStore(
    `RemoveNumber`,
    customerId,
    PRODUCT_CODES.maxCloudUC,
    body
  )

export const orderingServiceRemoveInfoGet = (OrderId, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `RemoveNumber?OrderId=${OrderId}`,
    '',
    customerId,
    PRODUCT_CODES.maxCloudUC
  )

export const orderingServiceCoreViewNewNumRequest = (data, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGStore(
    `NewNumberRequest`,
    customerId,
    PRODUCT_CODES.maxCloudUC,
    data
  )

export const orderingServiceCoreViewNewNumRequestGet = (orderId, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `NewNumberRequest?OrderId=${orderId}`,
    '',
    customerId,
    PRODUCT_CODES.maxCloudUC
  )

export const orderingServiceCompanyById = (params, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `company`,
    params,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )

export const api_MaxCloudGetBundles = (params, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGFetch(
    `bundle/all`,
    params,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )

export const orderingServiceE911Post = (body, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGStore(
    `StaticE911`,
    customerId,
    PRODUCT_CODES.maxCloudUC,
    body
  )

export const orderingServiceE91Update = (body, customerId) =>
  externalGENRICFUNCTIONALITYROUTINGUpdate(
    `StaticE911`,
    body,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )

//PATCH
export const orderingServiceCoreViewCallForwardBackupRemove = (
  body,
  number,
  customerId
) =>
  externalGENRICFUNCTIONALITYROUTINGPatch(
    `DidNumber/RemoveCallForwardingOrBackUp?Number=${number}`,
    body,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )

export const orderingServiceCoreViewTollFreeNumberPatch = (
  body,
  number,
  customerId
) =>
  externalGENRICFUNCTIONALITYROUTINGPatch(
    `DidNumber/tollfree?Number=${number}`,
    body,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )

export const orderingServiceCoreViewDIDNumberPatch = (
  body,
  number,
  customerId
) =>
  externalGENRICFUNCTIONALITYROUTINGPatch(
    `DidNumber/did?Number=${number}`,
    body,
    customerId,
    PRODUCT_CODES.maxCloudUC
  )
//DELETE

// Order Activation
export const orderActivatedByMaxClouduc = (params) =>
  externalCoreViewFetch(
    `Scheduler/order/activate?ct=${params.ct}&oid=${params.oid}`,
    {},
    params.companyId
  )

// Get NetSap Users
export const getAllNetSapiensUser = (params, companyId) =>
  externalCoreViewFetch(
    `NetSapUser/GetUser?domainName=${params}`,
    {},
    companyId
  )

// Get NetSap Admin Users
export const getNetSapiensAdminUser = (params, companyId) =>
  externalCoreViewFetch(
    `NetSapUser/GetAdminUser?domainName=${params}`,
    {},
    companyId
  )

// Create NetSap User
export const createNetSapiensAdminUser = (customerId, params, body) =>
  externalCoreViewStore(
    `NetSapUser/CreateAdminUser?domainName=${params.domain}&userName=${params.user}`,
    body,
    customerId
  )
// Create NetSap  User
export const createNetSapiensUser = (customerId, params, body) =>
  externalCoreViewStore(
    `NetSapUser/CreateUser?domainName=${params.domain}&userName=${params.user}`,
    body,
    customerId
  )
// Update NetSap User
export const updateNetSapiensUser = (customerId, params, body) =>
  externalCoreViewUpdate(
    `NetSapUser/UpdateUser?domainName=${params.domain}&userName=${params.user}`,
    body,
    customerId
  )
// Delete NetSap User
export const deleteNetSapiensUser = (customerId, params) =>
  externalCoreViewDestroy(
    `NetSapUser/DeleteUser?domainName=${params.domain}&userName=${params.user}`,
    customerId
  )
// Get Permission Scope for NetSap User
export const getPermissionScopeForAdmin = (customerId) =>
  externalCoreViewFetch(
    `NetSapMasterData/permissionScopeForAdmin`,
    {},
    customerId
  )
// Get Permission Scope for Number Assignment
export const getPermissionScopeForNumberAssignment = (customerId) =>
  externalCoreViewFetch(
    `NetSapMasterData/permissionScopeForNumberAssignment`,
    {},
    customerId
  )

// Send Email To Net Sapiens User
export const sendWelcomeEmailToNetSapiensUser = (customerId, params, body) =>
  externalCoreViewStore(
    `NetSapUser/SendWelcomeEmailToNetSapiensUser?domainName=${params.domain}&userName=${params.user}`,
    body,
    customerId
  )

export const getTimeZones = (customerId) =>
  externalCoreViewFetch(`NetSapMasterData/timeZones`, {}, customerId)

export const getNetSapiensUsersForDomainUpdate = (customerId, domainName) =>
  externalCoreViewFetch(
    `NetSapUser/GetNetSapiensUsersForDomainUpdate?domainName=${domainName}`,
    {},
    customerId
  )

export const getVoiceMailTranscription = (customerId) =>
  externalCoreViewFetch(
    `NetSapMasterData/voiceMailTranscription`,
    {},
    customerId
  )

// create Domain
export const createDomain = async (companyId, requestBody) => {
  return await externalCoreViewStore(
    `NetSapDomain/CreateDomain`,
    requestBody,
    companyId
  )
}
// Update Domain
export const updateDomain = (customerId, body) =>
  externalCoreViewUpdate(
    `NetSapDomain/Updatedomain/${body.domain}`,
    body,
    customerId
  )

export const api_MaxUCloudGetDomains = (customerId, params) =>
  externalCoreViewFetch(
    `NetSapDomain/GetDomainByCompanyId?companyId=${customerId}`,
    params,
    customerId
  )

export const getAllDevicesList = (customerId) =>
  externalCoreViewFetch(`NetSapMasterData/deviceList`, {}, customerId)

export const getDevicesForUser = (customerId, params) =>
  externalCoreViewFetch(
    `NetSapDevice/GetDevicesForUser?userID=${params.userId}&domainName=${params.domain}`,
    {},
    customerId
  )

export const createDevice = async (companyId, requestBody, params) => {
  return await externalCoreViewStore(
    `NetSapDevice/CreateDevice?domainName=${params.domain}&userName=${params.userId}`,
    requestBody,
    companyId
  )
}
export const deleteDevice = (customerId, params) =>
  externalCoreViewDestroyWithBody(
    `NetSapDevice/DeleteDevice?domainName=${params.domain}&deviceName=${params.deviceName}&userName=${params.userName}`,
    customerId
  )

export const getNetSapiensAccess = (email, customerId) =>
  externalCoreViewFetch(
    `NetSapUser/RevealNetSapiensAccess?email=${encodeURIComponent(
      email
    )}&companyId=${customerId}`,
    {},
    customerId
  )
