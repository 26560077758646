import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

// mui
import { Button, Menu, MenuItem, Avatar } from '@mui/material'

// icons
import ApartmentIcon from '@mui/icons-material/Apartment'
import jwt_decode from 'jwt-decode'

// css
import './levelToggler.css'
import { getCompanyProfile, getNavigation } from '../../../../api/get/getApi'
import {
  setCurrentCompanyDetails,
  setCurrentPath,
  setRoutingLevel,
} from '../../../../store/slice/commonSlice'
import {
  getCompanyName,
  gettingCompanyId,
  RoutingLevelFunction,
  stringAvatar,
} from '../../../../helpers/HelperFunctions'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import { ExpandMore } from '@mui/icons-material'

const LevelToggler = () => {
  const { dispatch, state } = useCommonRedux()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  useEffect(() => {
    fetchAndSetRoutingLevel()
  }, [location.pathname])

  const fetchAndSetRoutingLevel = async () => {
    // Check if the data is different from the current routingLevel
    const data = await getNavigation(gettingCompanyId(navigate))
    dispatch(setRoutingLevel(data.data.result))
    dispatch(setCurrentPath(location.pathname))

    if (
      state.routingLevel?.length &&
      state.routingLevel[0].companyLevel != state.currentCompanyDetails?.id &&
      location.pathname?.slice(1)?.toLowerCase() !== 'customer'
    ) {
      const response = await getCompanyProfile(
        location.pathname?.slice(1)?.toLowerCase(),
        gettingCompanyId()
      )
      dispatch(setCurrentCompanyDetails(response.data.result))
    }
  }

  const handleClick = (event) => {
    state.routingLevel?.length > 1 && setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  /**
   * This function is used to navigate the user to a specific level based on the company level and company ID.
   * @param {Object} e - The event object.
   * @param {Object} data - The data object containing the company level and ID.
   */
  const navigationLevels = (e, data) => {
    RoutingLevelFunction(data)
  }
  if (state.routingLevel.length)
    return (
      <>
        <div>
          <Button
            id="basic-button"
            style={{
              display: 'inline-flex',
              textAlign: 'left',
              color: '#2E384D',
              marginTop: '14px',
            }}
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            endIcon={state.routingLevel?.length > 1 ? <ExpandMore /> : ''}
          >
            <Avatar
              src={state.currentCompanyDetails?.logoUrl}
              {...stringAvatar(`${state?.currentCompanyDetails?.name}`)}
              id="basic-button"
              sx={{
                width: '39px',
                height: '39px',
                border: `${
                  state.currentCompanyDetails?.logoUrl?.length === 0
                    ? '0px'
                    : '2px'
                } solid rgba(9,132,227, 1)`,
                boxShadow:
                  '0px 1px 3px rgba(50,104,185, 0.2), 0px 2px 4px rgba(50,104,185, 0.26), 0px 0px 2px rgba(50,104,185, 0.14)',
                backgroundColor:
                  state.currentCompanyDetails?.logoUrl?.length === 0
                    ? '#8080dc'
                    : 'rgba(250,250,250, 1)',
                borderRadius: '7px',
                margin: '10px',
              }}
              variant="rounded"
            ></Avatar>
            <div style={{ display: 'inline-block' }}>
              <span style={{ fontSize: '20px' }}>
                {state.routingLevel[0]?.companyName}
              </span>
              <br />
              <span
                style={{ fontSize: '10px', float: 'left', margin: '-5px 0px' }}
              >
                {getCompanyName(state.routingLevel[0]?.companyLevel)}
              </span>
            </div>
          </Button>

          {state.routingLevel[0]?.name ==
          state.routingLevel?.map((item) => item.companyName) ? null : (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              style={{ padding: '10px' }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {state.routingLevel?.map((value, index) => {
                //shouldnot validate with companyName,if two level company names are same conflicts may occur. instead use companyId
                if (value.companyId !== state.routingLevel[0]?.companyId) {
                  return (
                    <MenuItem
                      style={{
                        display: 'block',
                        padding: '23px 25px 8px',
                        marginTop: '0px',
                      }}
                      key={index}
                      onClick={(e) => navigationLevels(e, value)}
                    >
                      <>
                        <div
                          style={{ marginLeft: '-14px', marginTop: '-5px' }}
                          className="companylogo_and_name"
                        >
                          <span>
                            <ApartmentIcon />
                          </span>{' '}
                          &emsp;
                          <span
                            style={{ marginLeft: '-10px', marginTop: '-5px' }}
                          >
                            {value.companyName}
                          </span>
                        </div>
                        <br />
                        <div
                          style={{
                            fontSize: '12px',
                            margin: '-10px 0px 0 16px',
                          }}
                        >
                          {getCompanyName(value.companyLevel)}
                        </div>
                      </>
                    </MenuItem>
                  )
                }
              })}
            </Menu>
          )}
        </div>
      </>
    )
}

export default LevelToggler
