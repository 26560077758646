import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import React, { forwardRef } from 'react'
import SendIcon from '@mui/icons-material/Send';

const ThemeButton = (props, _ref) => {
  const { text, loading = false, ...buttonProps } = props
  return loading ? (
    <LoadingButton
    {...buttonProps}
      endIcon={<SendIcon />}
      loading={loading}
      loadingPosition="end"
      variant="contained"
    >
      {text}
    </LoadingButton>
  ) : (
    <Button {...buttonProps}>{text}</Button>
  )
}

export default forwardRef(ThemeButton)
