import { useState } from 'react'

//mui
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  TextField,
  Modal,
  CircularProgress,
  Skeleton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material'

// css
import './approval.css'

// css
import '../../auth/signup/components/payment/payment.css'
import visalogo from '../../../assets/VISA-Logo.png'
import mastercardlogo from '../../../assets/Mastercard-Logo.png'
import americanexpresslogo from '../../../assets/American-Express.png'

// icons
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

// usenavigation
import { useNavigate, useSearchParams } from 'react-router-dom'

// api
import {
  approveorrejectcustomer,
  approveorrejectreseller,
  companyApproval,
} from '../../../api/post/postApi'

import jwt_decode from 'jwt-decode'
import { useSelector } from 'react-redux'
import {
  homeSliceState,
  setDirectCustomersList,
  setIsToggleCustomer,
  setIsToggleReseller,
  setIsToggleServiceProvider,
  setResellersList,
  setResellerTrigger,
  setServiceProvidersList,
  setServiceProviderTrigger,
} from '../../../store/slice/homeSlice'
import {
  checkPermission,
  normalizeApiResponse,
} from '../../../helpers/HelperFunctions'
import { COMPANY_LEVELS, COMPANY_STATUS } from '../../../helpers/Constants'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux'
import { useHomeRedux } from '../Home'
import { useToastContext } from '../../../App'

export default function Approvalcompany(props) {
  const [searchParams] = useSearchParams()
  const { dispatch, state: commonState } = useCommonRedux()
  const { state: homeState } = useHomeRedux()
  const { toast } = useToastContext()
  const navigate = useNavigate()

  const state = useSelector(homeSliceState)

  // loading hooks
  const [approveloader, setapproveloader] = useState(false)
  const [rejectloader, setrejectapproval] = useState(false)

  const [approvalformType, setapprovalfromType] = useState('accountdetails')
  const approvalforms = (approvalformType) => {
    switch (approvalformType) {
      case 'accountdetails':
        return accountdetails()
      case 'companydetails':
        return companydetails()
      case 'billingcontact':
        return Billinginfo()
      case 'billinginfo':
        return BillingAddress()
      case 'paymentmethod':
        return paymentForm()
      case 'appearance':
        return appearance()
      case 'billingtype':
        return billingForm()
    }
  }
  const accountdetails = () => {
    return (
      <>
        <div className="approvalpage_formcontainer_fieldstyle">
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              {props?.accountdetailsdata.email ? (
                <>
                  {' '}
                  <span>Email</span>
                  <TextField
                    fullWidth
                    value={props?.accountdetailsdata.email}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '5px' }}
                  height="56px"
                  width="100%"
                ></Skeleton>
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              {props?.accountdetailsdata.firstName ? (
                <>
                  {' '}
                  <span>Firstname</span>
                  <TextField
                    fullWidth
                    value={props?.accountdetailsdata.firstName}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '5px' }}
                  height="56px"
                  width="100%"
                ></Skeleton>
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              {props?.accountdetailsdata.firstName ? (
                <>
                  <span>Lastname</span>
                  <TextField
                    fullWidth
                    value={props?.accountdetailsdata.lastName}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '5px' }}
                  height="56px"
                  width="100%"
                ></Skeleton>
              )}
            </Grid>
            <Grid item md={10} sm={10} xs={10}>
              {props?.accountdetailsdata.firstName ? (
                <>
                  <span>Phone</span>
                  <TextField
                    fullWidth
                    value={props?.accountdetailsdata.phone}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />
                </>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '5px' }}
                  height="56px"
                  width="100%"
                ></Skeleton>
              )}
            </Grid>
            <Grid item md={2} sm={2} xs={2}>
              {props?.accountdetailsdata.firstName ? (
                <>
                  <span>Ext</span>
                  <TextField
                    fullWidth
                    value={props?.accountdetailsdata.extNumber}
                    InputProps={{ readOnly: true }}
                    variant="outlined"
                  />{' '}
                </>
              ) : (
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: '5px' }}
                  height="56px"
                  width="100%"
                ></Skeleton>
              )}
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  const companydetails = () => {
    return (
      <>
        <div className="approvalpage_formcontainer_fieldstyle">
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <span>Company Name</span>
              <TextField
                fullWidth
                value={props.companydetails.companyName}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>Address - Line 1</span>
              <TextField
                fullWidth
                value={props.companydetails.streetNumber}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>Address - Line 2</span>
              <TextField
                fullWidth
                value={
                  props.companydetails.street == null
                    ? ''
                    : props.companydetails.street
                }
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>city</span>
              <TextField
                fullWidth
                value={props.companydetails.city}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>State</span>
              <TextField
                fullWidth
                value={props.companydetails.state}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={8} sm={8} xs={8}>
              <span>Country</span>
              <TextField
                fullWidth
                value={props.companydetails.country}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <span>Zip</span>
              <TextField
                fullWidth
                value={props.companydetails.zip}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={8} sm={8} xs={8}>
              <span>Phone</span>
              <TextField
                fullWidth
                value={props.companydetails.phone}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={4} sm={4} xs={4}>
              <span>Fax</span>
              <TextField
                fullWidth
                value={props.companydetails.fax}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  const Billinginfo = () => {
    return (
      <div className="approvalpage_formcontainer_fieldstyle">
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <span>Email</span>
            <TextField
              fullWidth
              value={props.billingcontact.email}
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} sm={6} xs={6}>
            <span>First Name</span>
            <TextField
              fullWidth
              value={props.billingcontact.firstName}
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>
          <Grid item md={6} sm={6} xs={6}>
            <span>Last Name</span>
            <TextField
              fullWidth
              value={props.billingcontact.lastName}
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} sm={6} xs={6}>
            <span>Mobile</span>
            <TextField
              fullWidth
              value={props.billingcontact.mobile}
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} sm={6} xs={6}>
            <span>Fax</span>
            <TextField
              fullWidth
              value={props.billingcontact.fax}
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          <Grid item md={8} sm={8} xs={8}>
            <span>Phone</span>
            <TextField
              fullWidth
              value={props.billingcontact.phone}
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>

          <Grid item md={4} sm={4} xs={4}>
            <span>PhoneExtension</span>
            <TextField
              value={props.billingcontact.phoneExtension}
              fullWidth
              InputProps={{ readOnly: true }}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </div>
    )
  }

  const BillingAddress = () => {
    return (
      <>
        <div className="approvalpage_formcontainer_fieldstyle">
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid item md={12} sm={12} xs={12}>
              <span>Company Name</span>
              <TextField
                fullWidth
                value={props?.billingaddress.companyName}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>Address Line 1</span>
              <TextField
                fullWidth
                value={props?.billingaddress.line1}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>Line2</span>
              <TextField
                fullWidth
                value={props?.billingaddress.line2}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>City</span>
              <TextField
                fullWidth
                value={props?.billingaddress.city}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>State</span>
              <TextField
                fullWidth
                value={props?.billingaddress.state}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>Country</span>
              <TextField
                fullWidth
                value={props?.billingaddress.country}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} sm={6} xs={6}>
              <span>Zip</span>
              <TextField
                fullWidth
                value={props?.billingaddress.zip}
                InputProps={{ readOnly: true }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </div>
      </>
    )
  }

  const paymentForm = () => {
    const bankaccount = null
    const creditcard = null
    return (
      <>
        <div className="approvalpage_formcontainer_fieldstyle">
          {props?.creditcard != null ? (
            <Grid container>
              <Grid item md={12}>
                <Box className="stepper-border-style stepper-main-contianer1 stepper-box-shadow-style">
                  <Box className="stepper-payemnt-container-1">
                    <Box className="stepper-payemnt-contianer-2">
                      <span></span>
                      <span>
                        <img
                          src={visalogo}
                          alt="visa"
                          width="50px"
                          height="auto"
                        />
                        &emsp;
                        <img
                          src={mastercardlogo}
                          alt="master"
                          width="50px"
                          height="auto"
                        />
                        &emsp;
                        <img
                          src={americanexpresslogo}
                          alt="ame"
                          width="50px"
                          height="auto"
                        />
                      </span>
                    </Box>
                  </Box>
                  <div style={{ padding: '20px' }}>
                    <Grid container columnSpacing={2} rowSpacing={3}>
                      <Grid item md={6} sm={6} xs={6}>
                        <span>First Name</span>
                        <TextField
                          value={
                            props?.creditcard == null
                              ? null
                              : props?.creditcard.firstName
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={6}>
                        <span>Last Name</span>
                        <TextField
                          value={
                            props?.creditcard == null
                              ? null
                              : props?.creditcard.lastName
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <span>Card Number</span>
                        <TextField
                          value={
                            props?.creditcard == null
                              ? null
                              : props?.creditcard.cardNumber
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={4} xs={4}>
                        <span>Exp Month</span>
                        <TextField
                          value={
                            props?.creditcard?.expriationMonth == 0
                              ? 'XX'
                              : props?.creditcard?.expriationMonth
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={4} xs={4}>
                        <span>Exp Year</span>
                        <TextField
                          value={'20XX'}
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                      <Grid item md={4} sm={4} xs={4}>
                        <span>CVV</span>
                        <TextField
                          value={
                            props?.creditcard == null
                              ? null
                              : props?.creditcard.cvv
                          }
                          fullWidth
                          variant="outlined"
                          size="small"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <span>Account Name</span>
                <TextField
                  value={
                    props?.paymentMethod == null
                      ? null
                      : props?.paymentMethod.bankAccount.accountName
                  }
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <span>Account Number</span>
                <TextField
                  value={
                    props?.paymentMethod == null
                      ? null
                      : props?.paymentMethod.bankAccount.accountNumber
                  }
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <span>Routing Number</span>
                <TextField
                  value={
                    props?.paymentMethod == null
                      ? null
                      : props?.paymentMethod.bankAccount.routingNumber
                  }
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <span>Account Type</span>
                <TextField
                  value={
                    props?.paymentMethod == null
                      ? null
                      : props?.paymentMethod.bankAccount.accountType
                  }
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <span>Bank Name</span>
                <TextField
                  value={
                    props?.paymentMethod == null
                      ? null
                      : props?.paymentMethod.bankAccount.bankName
                  }
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <span>Cheque Number</span>
                <TextField
                  value={
                    props?.paymentMethod == null
                      ? null
                      : props?.paymentMethod.bankAccount.checkNumber
                  }
                  fullWidth
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          )}

          {/* bank account  */}
        </div>
      </>
    )
  }

  const appearance = () => {
    return (
      <>
        <div className="approvalpage_formcontainer_fieldstyle">
          <div className="approvalpage_logo_container">
            <img
              src={props?.logourl?.logoUrl || ''}
              width="100%"
              height="70px"
              style={{ objectFit: 'contain' }}
            />
            <span>Logo</span>
          </div>
        </div>
      </>
    )
  }

  const billingForm = () => {
    const label = { inputProps: { 'aria-label': 'Switch demo' } }
    return (
      <>
        <div className="">
          <div className="approvalpage_billingtype_container">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={props?.billingtype.billingType}
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="1"
                  control={<Radio disabled={true} />}
                  label={
                    <>
                      Altigen will bill you
                      <span
                        style={{
                          display: 'inline-flex',
                          verticalAlign: 'middle',
                          color: '#4682b4',
                        }}
                      >
                        {/*<HelpIcon />*/}
                      </span>
                    </>
                  }
                />
                <FormControlLabel
                  value="2"
                  control={<Radio disabled={true} />}
                  label={
                    <>
                      Altigen will bill your Resellers/Direct Customers
                      <span
                        style={{
                          display: 'inline-flex',
                          verticalAlign: 'middle',
                          color: '#4682b4',
                        }}
                      >
                        {/*<HelpIcon />*/}
                      </span>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </>
    )
  }

  const [openremarks, setopenremarks] = useState(false)

  // reject function
  const rejectfunction = () => {
    setopenremarks(!openremarks)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  }

  const handleClose = () => setopenremarks(false)
  const [remarkmessage, setremarkmessage] = useState('')
  const [remarkmessagehelpertext, setremarkhelpertext] = useState('')
  const rejectCompany = (status) => {
    setremarkhelpertext('')
    if (
      (remarkmessage != '' && status != 'approve') ||
      (remarkmessage == '' && status == 'approve')
    ) {
      if (status == 'approve') {
        setapproveloader(true)
      } else {
        setrejectapproval(true)
      }

      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let currentcompanyId = searchParams.get('company_id')
      if (!currentcompanyId) {
        currentcompanyId = decoded?.company_id
      }

      const responseBody = {
        companyId: props?.spcompanyid,
        companyStatus:
          status == 'approve' ? COMPANY_STATUS.Active : COMPANY_STATUS.Rejected,
        remarks: remarkmessage,
      }
      const localToken = localStorage.getItem('sd_cp.jwt:tkn')
      const config = { headers: { Authorization: `Bearer ${localToken}` } }
      {
        props?.role == 'serviceprovider'
          ? serviceproviderapproval(responseBody, config, status)
          : props?.role == 'reseller'
          ? resellerapproval(responseBody, config, status)
          : props?.role == 'customer'
          ? customerapproval(responseBody, config, status)
          : null
      }
    } else {
      setremarkhelpertext('Please enter remarks before rejection')
    }
  }

  const serviceproviderapproval = async (responseBody, config, status) => {
    try {
      const data = await companyApproval(responseBody, config)
      setapproveloader(false)
      setrejectapproval(false)
      dispatch(setIsToggleServiceProvider(false))
      const index = state.serviceProvidersList?.findIndex(
        (o) => o.id === responseBody.companyId
      )
      const serviceProviders = [...state.serviceProvidersList]
      if (index != -1) {
        serviceProviders[index] = {
          ...serviceProviders[index],
          companyStatus: responseBody?.companyStatus,
        }
      }
      dispatch(setServiceProvidersList(serviceProviders))
      dispatch(setServiceProviderTrigger('triggered'))
      toast.showToast('Request success')
    } catch (error) {
      setapproveloader(false)
      setrejectapproval(false)
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  const resellerapproval = async (responseBody, config) => {
    try {
      const data = await approveorrejectreseller(responseBody, config)
      setapproveloader(false)
      setrejectapproval(false)
      dispatch(setIsToggleReseller(false))
      props?.showServiceProvider(true)
      const index = state.resellersList?.findIndex(
        (o) => o.id === responseBody.companyId
      )
      const resellers = [...state.resellersList]
      if (index != -1) {
        resellers[index] = {
          ...resellers[index],
          companyStatus: responseBody?.companyStatus,
        }
      }
      dispatch(setResellersList(resellers))
      dispatch(setResellerTrigger('triggered'))
      toast.showToast('Request success')
    } catch (error) {
      setapproveloader(false)
      setrejectapproval(false)
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  const customerapproval = async (responseBody, config, status) => {
    try {
      const data = await approveorrejectcustomer(responseBody, config)
      setapproveloader(false)
      setrejectapproval(false)
      const index = state.directCustomersList?.findIndex(
        (o) => o.id === responseBody?.companyId
      )
      const directCustomers = state.directCustomersList
        ? [...state.directCustomersList]
        : []
      if (index != -1) {
        directCustomers[index] = {
          ...directCustomers[index],
          companyStatus: responseBody?.companyStatus,
        }
      }
      props?.showServiceProvider(props?.isDirectCustomer)
      dispatch(setDirectCustomersList(directCustomers))
      dispatch(setIsToggleCustomer(false))
      if (props?.isDirectCustomer) {
        dispatch(setIsToggleServiceProvider(!props?.isDirectCustomer))
        dispatch(setServiceProviderTrigger('triggered'))
      }
      toast.showToast('Request success')
    } catch (error) {
      setapproveloader(false)
      setrejectapproval(false)
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  const approvefunction = () => {
    const status = 'approve'
    rejectCompany(status)
  }

  const toggleback = () => {
    if (props?.role == 'serviceprovider')
      dispatch(setIsToggleServiceProvider(false))
    if (props?.role == 'reseller') dispatch(setIsToggleReseller(false))
    if (props?.role == 'customer') {
      dispatch(setIsToggleCustomer(false))
      props?.showServiceProvider(props?.isCustomerApproval ? true : false)
    }
  }

  return (
    <>
      <Box sx={{ textAlign: 'right', paddingRight: '20px', my: '20px' }}>
        <Button onClick={toggleback} variant="outlined">
          {props?.role == 'serviceprovider' ? (
            <>
              <KeyboardBackspaceIcon /> &emsp; ServiceProvider List
            </>
          ) : props?.role == 'reseller' ? (
            <>
              <KeyboardBackspaceIcon /> &emsp; Reseller's List
            </>
          ) : props?.role == 'customer' ? (
            <>
              <KeyboardBackspaceIcon /> &emsp; Customer's List
            </>
          ) : null}
        </Button>
      </Box>

      <div className="approvalcompany_main_container">
      <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          {props?.fulldata?.directCustomer === true && 
          <>
          <Button onClick={() => setapprovalfromType('accountdetails')}>
            Account Details
          </Button>
          <Button onClick={() => setapprovalfromType('companydetails')}>
            Company Details
          </Button>
          {(props?.fulldata?.companyLevel === COMPANY_LEVELS.customer) && <>
            <Button onClick={() => setapprovalfromType('billingcontact')}>
              billing Contact
            </Button>
            <Button onClick={() => setapprovalfromType('billinginfo')}>
              billing Address
            </Button></>}
          {props?.fulldata?.companyLevel == COMPANY_LEVELS.serviceprovider ? (
            <Button onClick={() => setapprovalfromType('billingtype')}>
              billing Type
            </Button>
          ) : (
            ''
          )}
          {props?.fulldata?.isAltigenBilling ? (
            <Button onClick={() => setapprovalfromType('paymentmethod')}>
              payment Method
            </Button>
          ) : (
            ''
          )}
          <Button onClick={() => setapprovalfromType('appearance')}>
            Appearance
          </Button> </> }

          {props?.fulldata?.directCustomer === false && 
          <>
          <Button onClick={() => setapprovalfromType('accountdetails')}>
            Account Details
          </Button>
          <Button onClick={() => setapprovalfromType('companydetails')}>
            Company Details
          </Button>
          <Button onClick={() => setapprovalfromType('appearance')}>
            Appearance
          </Button> </> }
        </ButtonGroup>
        <div className="approvalpage_formcontainer">
          {approvalforms(approvalformType)}
          <div>
            {checkPermission(
              [props?.permissions],
              commonState.currentCompanyDetails,
              props?.level,
              props?.upperLevel
            ) ? (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  variant="contained"
                  color="success"
                  sx={{ color: 'white' }}
                  onClick={approvefunction}
                >
                  Approve{' '}
                  {approveloader ? (
                    <>
                      {' '}
                      &emsp;{' '}
                      <CircularProgress sx={{ color: '#fff' }} size={20} />{' '}
                    </>
                  ) : null}{' '}
                </Button>{' '}
                &emsp;
                <Button
                  onClick={rejectfunction}
                  variant="contained"
                  color="error"
                >
                  Reject with remarks
                </Button>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      <Modal open={openremarks} onClose={handleClose}>
        <Box sx={style}>
          <TextField
            helperText={remarkmessagehelpertext}
            error={remarkmessagehelpertext != '' ? true : false}
            label="Remarks"
            onChange={(e) => setremarkmessage(e.target.value)}
            fullWidth
            multiline
            rows={6}
          />
          <Box sx={{ textAlign: 'center', pt: '5px' }}>
            <Button variant="contained" onClick={rejectCompany}>
              Reject{' '}
              {rejectloader ? (
                <>
                  {' '}
                  &emsp; <CircularProgress
                    sx={{ color: '#fff' }}
                    size={20}
                  />{' '}
                </>
              ) : null}{' '}
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  )
}
