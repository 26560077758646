import React, { useState } from 'react'
import { TextField, Chip, Box } from '@mui/material'

const TagInput = ({
  value = [],
  onChange,
  onValidate = () => true,
  name,
  allowDuplicates = false,
  placeholder = 'Add email...',
  errorMessage = 'Invalid email format',
  inputProps = {},
  isEmailEmpty = false,
}) => {
  const [inputValue, setInputValue] = useState('')
  const [isValid, setIsValid] = useState(true)

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault()
      const newTag = inputValue.trim()
      if (!newTag) return

      if (onValidate(newTag) && (allowDuplicates || !value.includes(newTag))) {
        onChange({ target: { value: [...value, newTag], name } })
        setInputValue('')
        setIsValid(true)
      } else {
        setIsValid(false)
      }
    }
    //else if (event.key === 'Backspace' && !inputValue && value.length > 0) {
    //   onChange({ target: { value: value.slice(0, -1), name } })
    // }
  }

  const handleDelete = (tagToDelete) => {
    onChange({
      target: { value: value.filter((tag) => tag !== tagToDelete), name },
    })
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '100%' }}>
      {/* Input Field */}
      <TextField
        variant="standard"
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => {
          const trimmedValue = e.target.value.trimStart()
          setInputValue(trimmedValue)
          setIsValid(onValidate(trimmedValue) || trimmedValue === '')
        }}
        onKeyDown={handleKeyDown}
        error={!isValid || (isEmailEmpty && inputValue == '')} // Error state if email is invalid or empty
        helperText={
          !isValid
            ? errorMessage
            : isEmailEmpty && inputValue == ''
            ? 'The Field is required'
            : inputValue
            ? 'Press Enter to add the email.'
            : ''
        }
        sx={{ width: '100%' }}
        {...inputProps}
      />

      {/* Tags Container (Only visible if there is at least one tag) */}
      {value.length > 0 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Ensures emails are listed one below the other
            gap: 0.5,
            maxHeight: '120px', // Height for 3 email tags
            overflowY: value.length > 3 ? 'scroll' : 'hidden',
            border: '1px solid #ccc',
            borderRadius: 1,
            padding: 1,
            mt: 1, // Adds spacing between input and tags
          }}
        >
          {value.map((tag, index) => (
            <Chip
              key={tag}
              label={tag}
              onDelete={() => handleDelete(tag)}
              color="primary"
              sx={{ width: 'fit-content' }} // Ensures chips don't stack inline
            />
          ))}
        </Box>
      )}
    </Box>
  )
}

export default TagInput
