import Box from '@mui/material/Box'
import {
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import * as React from 'react'
import { useFormik } from 'formik'
import {
  masterRoleAllByIdApi,
  masterUserPostApi,
} from '../../../api/master/masterAPI'
import { useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import LoadingSection from '../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import {
  getCompanyLevel,
  normalizeApiResponse,
  requiredLabel,
} from '../../../helpers/HelperFunctions'
import { UserFormValidation } from '../../../validations/UserManagementValidation'
import { useToastContext } from '../../../App'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230,
    },
  },
}
export function AddNewUser(props) {
  const { toast } = useToastContext()
  const [roleList, setRoleList] = React.useState([])
  const [roleIds, setRoleIds] = React.useState([])
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const getRoleAllByIdApi = (id) => {
    masterRoleAllByIdApi(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    ).then((response) => {
      setRoleList(response.data.result)
    })
  }
  useEffect(() => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    let companyId = searchParams.get('company_id')
    if (!companyId) {
      companyId = decoded?.company_id
    }
    getRoleAllByIdApi(companyId)
  }, [])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      roleIds: [],
      companyId: '',
      aadUserId: '',
      userStatus: 1
    },
    validationSchema: UserFormValidation,
    onSubmit: (value) => {
      const data = { ...value }
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      delete data.mobile
      data.phone = value.mobile
      setRoleIds(data.roleIds)
      let mapRole = data.roleIds?.map((rol) => {
        return rol.roleId
      })
      let filtMap = mapRole.filter((filt) => filt)
      data.roleIds = filtMap
      masterUserPostApi(
        data,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          props.toggleDrawer('right', false, '', '', true)
          setSubmitLoader(false)
          toast.showToast(response.data.message, 'success')
        })
        .catch((error) => {
          setRoleIds((prev) => {
            formik.setFieldValue('roleIds', prev)
          })
          setSubmitLoader(false)
          toast.showToast( normalizeApiResponse(error)?.message, 'error')
        })
    },
  })

  const onChangeRole = (e) => {
    props?.toggleDrawer('right', false)
  }
  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Box role="presentation">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            padding: '5px 10px 0px 20px',
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              columnSpacing={4}
              rowSpacing={3}
              sx={{ mb: '20px' }}
            >
              <Grid item md={6} sm={6} xs={6}>
                {requiredLabel('First Name')}
                <TextField
                  id="firstName"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div style={{ color: 'red', position: 'absolute' }}>
                  {formik.errors.firstName ? formik.errors.firstName : null}
                </div>
              </Grid>
              <Grid item md={6} sm={6} xs={6}>
                {requiredLabel('Last Name')}
                <TextField
                  id="lastName"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div style={{ color: 'red', position: 'absolute' }}>
                  {formik.errors.lastName ? formik.errors.lastName : null}
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {requiredLabel('Email')}
                <TextField
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div style={{ color: 'red', position: 'absolute' }}>
                  {formik.errors.email ? formik.errors.email : null}
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {requiredLabel('Phone Number')}
                <TextField
                  id="mobile"
                  name="mobile"
                  type="number"
                  onChange={(e) => {
                    if (e.target.value?.length <= 10)
                      formik.setFieldValue('mobile', e.target.value)
                  }}
                  value={formik.values.mobile}
                  fullWidth
                  InputProps={{
                    style: {
                      height: '35px',
                    },
                  }}
                />
                <div style={{ color: 'red', position: 'absolute' }}>
                  {formik.errors.mobile ? formik.errors.mobile : null}
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                {requiredLabel('Select Roles')}
                <div>
                  <FormControl fullWidth>
                    <Select
                      id="roleIds"
                      name="roleIds"
                      multiple
                      style={{ height: '35px' }}
                      value={formik.values.roleIds}
                      onChange={(e) => {
                        formik.setFieldValue('roleIds', e.target.value)
                        // formik.values.roleIds(e.target.value);
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                          {selected.map((value, index) => (
                            <Chip
                              size="small"
                              key={index}
                              label={value.roleName}
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {roleList.map((name, index) => (
                        <MenuItem key={name.roleId} value={name}>
                          {name.roleName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div style={{ color: 'red', position: 'absolute' }}>
                    {formik.errors.roleIds ? formik.errors.roleIds : null}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Box
              style={{
                position: 'fixed',
                marginTop: '15px',
                background: '#fff',
                display: 'flex',
              }}
            >
              <Button
                disabled={!(formik.isValid && formik.dirty)}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                size="large"
              >
                Add New User
              </Button>
              <div style={{ marginLeft: '20px' }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    props.toggleDrawer('right', false, '', '', false)
                  }
                  disableElevation
                  size="large"
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </form>
        </div>
      </Box>
    </>
  )
}

export default AddNewUser
