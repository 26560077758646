import Box from '@mui/material/Box'
import { Button, Checkbox, FormControlLabel, Grid, List, TextField } from '@mui/material';
import * as React from 'react'
import { useFormik } from 'formik'
import {
  masterRoleByIdPrivilegesApi,
  masterRolePutApi,
} from '../../../api/master/masterAPI'
import { useEffect } from 'react'
import jwt_decode from 'jwt-decode'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader'
import LoadingSection from '../../../common/components/loaders/loader'

import { useLocation, useSearchParams } from 'react-router-dom'
import {
  getCompanyLevel,
  normalizeApiResponse,
  requiredLabel,
} from '../../../helpers/HelperFunctions'
import { RoleFormValidation } from '../../../validations/UserManagementValidation'
import { useToastContext } from '../../../App'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux';
import { COMPANY_LEVELS, SERVICE_MANAGEMENT_CREATE_SERVICE_ID } from '../../../helpers/Constants';
// const allManagements = [];
export function EditRole(props) {
  const { dispatch, state: commonState } = useCommonRedux();
  const { toast } = useToastContext();
  const [loader, setLoader] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const [allManagement, setAllManagement] = React.useState([])
  const formik = useFormik({
    initialValues: {
      id: props?.details?.roleId,
      roleName: props?.details?.roleName,
      companyId: props?.details?.companyId,
      description: props?.details?.description,
      isSuperAdminRole: props?.details?.isSuperAdminRole,
      roleStatus: props?.details?.roleStatus,
      privileges: props?.details?.privileges,
    },
    validationSchema: RoleFormValidation,
    onSubmit: (data) => {
      checkSPCompanyView(permissionList.privileges)
      checkResellerCompanyView(permissionList.privileges)
      checkCustomerCompanyView(permissionList.privileges)
      checkServiceSelectedBool(permissionList.privileges)
      let privileges = permissionList.privileges.map((rol) => {
        let rols = rol.privileges.map((rolChild) => {

          if (rolChild.isAssigned) {
            return rolChild.id
          }
        })
        if (rols) return rols
      })
      let concatPrivileges = privileges.join(',').split(',')
      let removeBlankArray = concatPrivileges.filter((filt) => filt)

      data.privileges = []
      data.privileges = removeBlankArray
      if(commonState.currentCompanyDetails?.companyLevel ==
        COMPANY_LEVELS.customer && !commonState.currentCompanyDetails.directCustomer){
          data.privileges = data.privileges.filter(item => item !== SERVICE_MANAGEMENT_CREATE_SERVICE_ID);
        }
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      if (removeBlankArray.length > 0) {
        if (
          (checkSPCompanyView(permissionList.privileges, 'serviceprovider') &&
            checkResellerCompanyView(permissionList.privileges, 'reseller') &&
            checkCustomerCompanyView(permissionList.privileges, 'customer') &&
            'master' == location.pathname.slice(1)) ||
          (checkSPCompanyView(permissionList.privileges, 'serviceprovider') &&
            checkResellerCompanyView(permissionList.privileges, 'reseller') &&
            checkCustomerCompanyView(permissionList.privileges, 'customer') &&
            'serviceprovider' == location.pathname.slice(1)) ||
          (checkResellerCompanyView(permissionList.privileges, 'reseller') &&
            checkCustomerCompanyView(permissionList.privileges, 'customer') &&
            'reseller' == location.pathname.slice(1)) ||
          (checkCustomerCompanyView(permissionList.privileges, 'customer') &&
            'customer' == location.pathname.slice(1))
        ) {
          if (checkServiceSelectedBool(permissionList.privileges)) {
            data.companyId = companyId
            setSubmitLoader(true)
            masterRolePutApi(
              data,
              getCompanyLevel(
                searchParams.get('company_id'),
                location.pathname.slice(1)
              )
            )
              .then((response) => {
                toast.showToast(response.data.message, 'success')
                setTimeout(() => {
                  props.gotoDrawer('view')
                  const updatedDetails = {
                    ...props.details,
                    roleName: response.data.result.roleName,
                  }
                  props.toggleDrawer(
                    'right',
                    true,
                    'view',
                    updatedDetails,
                    true
                  )
                }, 100)
                setSubmitLoader(false)
              })
              .catch((error) => {
                setSubmitLoader(false)
                toast.showToast( normalizeApiResponse(error)?.message, 'error')
              })
          }
        } else {
          toast.showToast('Company View should be selected', 'error')
        }
      } else {
        toast.showToast(
          'Minimum one permission should be selected',
          'error'
        )
      }
    },
  })

  const [permissionList, setPermissionList] = React.useState([])
  const getRoleByIdPrivilegesApi = (id) => {
    setLoader(true)
    masterRoleByIdPrivilegesApi(
      id,
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
      .then((response) => {
        const privileges = response.data.result
        let billingFilter = response.data.result
        if (location.pathname.slice(1) == 'master') {
          billingFilter = response.data.result
        } else if (
          location.pathname.slice(1) == 'serviceprovider' &&
          !commonState.currentCompanyDetails?.isAltigenBilling
        ) {
          const billingPreviligesFilter = privileges.privileges.filter(
            (datFilt) => datFilt.groupName !== 'Billing'
          )
          billingFilter.privileges = billingPreviligesFilter
        } else if (
          location.pathname.slice(1) == 'serviceprovider' &&
          commonState.currentCompanyDetails?.isAltigenBilling
        ) {
          const billingPreviligesFilter = privileges.privileges.filter(
            (datFilt) =>
              datFilt.groupName !== 'Reseller Billing' &&
              datFilt.groupName !== 'Customer Billing'
          )
          billingFilter.privileges = billingPreviligesFilter
        } else if (
          location.pathname.slice(1) == 'reseller' &&
          commonState.currentCompanyDetails?.isAltigenBilling
        ) {
          const billingPreviligesFilter = privileges.privileges.filter(
            (datFilt) => datFilt.groupName !== 'Customer Billing'
          )
          billingFilter.privileges = billingPreviligesFilter
        } else if (
          location.pathname.slice(1) == 'reseller' &&
          !commonState.currentCompanyDetails?.isAltigenBilling &&
          commonState.currentCompanyDetails?.isRevParent
        ) {
          const billingPreviligesFilter = privileges.privileges.filter(
            (datFilt) => datFilt.groupName !== 'Billing'
          )
          billingFilter.privileges = billingPreviligesFilter
        } else if (
          location.pathname.slice(1) == 'customer' &&
          commonState.currentCompanyDetails?.isAltigenBilling
        ) {
          const billingPreviligesFilter = privileges.privileges
          billingFilter.privileges = billingPreviligesFilter
        } else {
          const billingPreviligesFilter = privileges.privileges.filter(
            (datFilt) => !datFilt.groupName.includes('Billing')
          )
          billingFilter.privileges = billingPreviligesFilter
        }
        let allManagements = []
        billingFilter.privileges.forEach((dat, index1) => {
          setAllManagement((prevState) => {
            let checkedRoles = billingFilter.privileges[
              index1
            ].privileges.filter((o) => o.isAssigned == true)
            let noOfCheckedItems = checkedRoles.length
            if (
              noOfCheckedItems ===
              billingFilter?.privileges[index1]?.privileges.length
            ) {
              allManagements.push(true)
            } else {
              allManagements.push(false)
            }
            //
            return allManagements
          })
        })
        setPermissionList(billingFilter)
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        toast.showToast(normalizeApiResponse(error)?.message, 'error')
      })
  }

  const handleAllPrivileges = (e, role, index) => {
    setPermissionList((prev) => {
      let newState = { ...prev }
      let isNotEmptyRole = role != null || role != undefined || role != []
      if (e.target.checked) {
        newState.privileges.forEach((dat) => {
          if (dat.groupName.toLowerCase().includes('serviceprovider')) {
            if (dat.groupName == 'ServiceProvider Company') {
              dat.privileges.forEach((privDat) => {
                if (privDat.value.toLowerCase().includes('view')) {
                  privDat.isAssigned = true
                }
              })
            }
          }
        })
        newState.privileges.forEach((dat) => {
          if (dat.groupName.toLowerCase().includes('reseller')) {
            if (dat.groupName == 'Reseller Company') {
              dat.privileges.forEach((privDat) => {
                if (privDat.value.toLowerCase().includes('view')) {
                  privDat.isAssigned = true
                }
              })
            }
          }
        })
        newState.privileges.forEach((dat) => {
          if (dat.groupName.toLowerCase().includes('customer')) {
            if (dat.groupName == 'Customer Company') {
              dat.privileges.forEach((privDat) => {
                if (privDat.value.toLowerCase().includes('view')) {
                  privDat.isAssigned = true
                }
              })
            }
          }
        })
        newState.privileges.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('customer.product.create') ||
              privDatService.value
                .toLowerCase()
                .includes('customer.product.update')
            ) {
              newState.privileges.forEach((datProductService) => {
                datProductService.privileges.forEach(
                  (privProudctDatService) => {
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('reseller.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('serviceprovider.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('master.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                  }
                )
              })
            }
          })
        })
        newState.privileges.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('reseller.product.create') ||
              privDatService.value
                .toLowerCase()
                .includes('reseller.product.update')
            ) {
              newState.privileges.forEach((datProductService) => {
                datProductService.privileges.forEach(
                  (privProudctDatService) => {
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('serviceprovider.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('master.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                  }
                )
              })
            }
          })
        })
        newState.privileges.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('serviceprovider.product.create') ||
              privDatService.value
                .toLowerCase()
                .includes('serviceprovider.product.update')
            ) {
              newState.privileges.forEach((datProductService) => {
                datProductService.privileges.forEach(
                  (privProudctDatService) => {
                    if (
                      privProudctDatService.value
                        .toLowerCase()
                        .includes('master.product.view')
                    ) {
                      privProudctDatService.isAssigned = true
                    }
                  }
                )
              })
            }
          })
        })
      }
      if (!e.target.checked && isNotEmptyRole) {
        newState.privileges[index].privileges.map(
          (obj, i) => (obj.isAssigned = e.target.checked)
        )
        setAllManagement((prevState) =>
          prevState.map((item, i) => (i === index ? !item : item))
        )
      } else if (e.target.checked && isNotEmptyRole) {
        newState.privileges[index].privileges.map(
          (obj, i) => (obj.isAssigned = e.target.checked)
        )
        setAllManagement((prevState) =>
          prevState.map((item, i) => (i === index ? !item : item))
        )
      }
      return newState
    })
  }
  const handlePrivileges = (e, index1, index2) => {
    setPermissionList((prev) => {
      let newState = { ...prev }
      newState.privileges[index1].privileges[index2].isAssigned =
        e.target.checked

      //--- update bind with view ---

      if (
        (newState.privileges[index1].privileges[index2].displayName
          .toLowerCase()
          .includes('update') ||
          newState.privileges[index1].privileges[index2].displayName
            .toLowerCase()
            .includes('create') ||
          newState.privileges[index1].privileges[index2].displayName
            .toLowerCase()
            .includes('delete')) &&
        e.target.checked
      ) {
        newState.privileges[index1].privileges.forEach((item, i) => {
          item.displayName.toLowerCase().includes('view') &&
            (item.isAssigned = true)
        })
      }
      if (
        newState.privileges[index1].privileges[index2].displayName
          .toLowerCase()
          .includes('view') &&
        !e.target.checked
      ) {
        newState.privileges[index1].privileges.forEach((item, i) => {
           (item.displayName.toLowerCase().includes('update') ||
            item.displayName.toLowerCase().includes('create') ||
            item.displayName.toLowerCase().includes('delete')) &&
            (item.isAssigned = false)
        })
        let checkedRoles = newState.privileges[index1].privileges.filter(
          (o) => o.isAssigned == true
        )
        // if(checkedRoles.length > 1){
        setAllManagement((prevState) => {
          if (newState.privileges[index1].privileges.length > 1) {
            prevState[index1] = false
          }
          return prevState
        })
        // }
      }
      if (
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('serviceprovider')
      ) {
        newState.privileges.forEach((dat) => {
          if (dat.groupName == 'ServiceProvider Company') {
            dat.privileges.forEach((privDat) => {
              if (privDat.value.toLowerCase().includes('view')) {
                privDat.isAssigned = true
              }
            })
          }
        })
      }
      if (
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('reseller')
      ) {
        newState.privileges.forEach((dat) => {
          if (dat.groupName == 'Reseller Company') {
            dat.privileges.forEach((privDat) => {
              if (privDat.value.toLowerCase().includes('view')) {
                privDat.isAssigned = true
              }
            })
          }
        })
      }
      if (
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('customer')
      ) {
        newState.privileges.forEach((dat) => {
          if (dat.groupName == 'Customer Company') {
            dat.privileges.forEach((privDat) => {
              if (privDat.value.toLowerCase().includes('view')) {
                privDat.isAssigned = true
              }
            })
          }
        })
      }
      if (
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('customer.product.create') ||
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('customer.product.update')
      ) {
        newState.privileges.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('reseller.product.view')
            ) {
              privDatService.isAssigned = true
            }
            if (
              privDatService.value
                .toLowerCase()
                .includes('serviceprovider.product.view')
            ) {
              privDatService.isAssigned = true
            }
            if (
              privDatService.value.toLowerCase().includes('master.product.view')
            ) {
              privDatService.isAssigned = true
            }
          })
        })
      }
      if (
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('reseller.product.create') ||
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('reseller.product.update')
      ) {
        newState.privileges.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value
                .toLowerCase()
                .includes('serviceprovider.product.view')
            ) {
              privDatService.isAssigned = true
            }
            if (
              privDatService.value.toLowerCase().includes('master.product.view')
            ) {
              privDatService.isAssigned = true
            }
          })
        })
      }
      if (
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('serviceprovider.product.create') ||
        newState.privileges[index1].privileges[index2].value
          .toLowerCase()
          .includes('serviceprovider.product.update')
      ) {
        newState.privileges.forEach((datService) => {
          datService.privileges.forEach((privDatService) => {
            if (
              privDatService.value.toLowerCase().includes('master.product.view')
            ) {
              privDatService.isAssigned = true
            }
          })
        })
      }
      let checkedRoles = newState.privileges[index1].privileges.filter(
        (o) => o.isAssigned == true
      )
      let noOfCheckedItems = checkedRoles.length

      if (
        (noOfCheckedItems === newState?.privileges[index1]?.privileges.length &&
          e.target.checked) ||
        (noOfCheckedItems ===
          newState?.privileges[index1]?.privileges.length - 1 &&
          !e.target.checked)
      ) {
        setAllManagement((prevState) =>
          prevState.map((item, i) => (i === index1 ? !item : item))
        )
      }
      return newState
    })
  }
  const checkSPCompanyView = (permission) => {
    let boolPermission = false
    const rolsSPFilter = permission.filter((rolChild, ind) => {
      if (rolChild.groupName.toLowerCase().includes('company')) {
        return rolChild.privileges
      }
    })
    rolsSPFilter.forEach((dat) => {
      if (dat.groupName.toLowerCase().includes('company')) {
        for (let i = 0; i < dat.privileges.length; i++) {
          if (
            dat.privileges[i].value.toLowerCase().includes('serviceprovider') &&
            dat.privileges[i].value.toLowerCase().includes('view')
          ) {
            boolPermission = dat.privileges.some((val) => val.isAssigned)
            break
          }
        }
      }
    })
    return boolPermission
  }
  const checkResellerCompanyView = (permission) => {
    let boolPermission = false
    const rolsSPFilter = permission.filter((rolChild, ind) => {
      if (rolChild.groupName.toLowerCase().includes('company')) {
        return rolChild.privileges
      }
    })
    rolsSPFilter.forEach((dat) => {
      if (dat.groupName.toLowerCase().includes('company')) {
        for (let i = 0; i < dat.privileges.length; i++) {
          if (
            dat.privileges[i].value.toLowerCase().includes('reseller') &&
            dat.privileges[i].value.toLowerCase().includes('view')
          ) {
            boolPermission = dat.privileges.some((val) => val.isAssigned)
            return boolPermission
          }
        }
      }
    })
    return boolPermission
  }
  const checkCustomerCompanyView = (permission) => {
    let boolPermission = false
    const rolsSPFilter = permission.filter((rolChild, ind) => {
      if (rolChild.groupName.toLowerCase().includes('company')) {
        return rolChild.privileges
      }
    })
    rolsSPFilter.forEach((dat) => {
      if (dat.groupName.toLowerCase().includes('company')) {
        for (let i = 0; i < dat.privileges.length; i++) {
          if (
            dat.privileges[i].value.toLowerCase().includes('customer') &&
            dat.privileges[i].value.toLowerCase().includes('view')
          ) {
            boolPermission = dat.privileges.some((val) => val.isAssigned)
            return boolPermission
          }
        }
      }
    })
    return boolPermission
  }
  const checkServiceSelectedBool = (permission) => {
    let boolPermission = true
    permission.forEach((datService) => {
      datService.privileges.forEach((privDatService) => {
        if (
          privDatService.value
            .toLowerCase()
            .includes('customer.product.create') ||
          privDatService.value.toLowerCase().includes('customer.product.update')
        ) {
          if (privDatService.isAssigned) {
            permission.forEach((datProductService) => {
              datProductService.privileges.forEach((privProudctDatService) => {
                if (
                  privProudctDatService.value
                    .toLowerCase()
                    .includes('reseller.product.view')
                ) {
                  if (!privProudctDatService.isAssigned) {
                    toast.showToast(
                      'Please select View Service permission on Reseller Service Management',
                      'error'
                    )
                    boolPermission = false
                  }
                }
              })
            })
          }
        }
      })
    })
    permission.forEach((datService) => {
      datService.privileges.forEach((privDatService) => {
        if (
          privDatService.value
            .toLowerCase()
            .includes('reseller.product.create') ||
          privDatService.value.toLowerCase().includes('reseller.product.update')
        ) {
          if (privDatService.isAssigned) {
            permission.forEach((datProductService) => {
              datProductService.privileges.forEach((privProudctDatService) => {
                if (
                  privProudctDatService.value
                    .toLowerCase()
                    .includes('serviceprovider.product.view')
                ) {
                  if (!privProudctDatService.isAssigned) {
                    toast.showToast(
                      'Please select View Service permission on Serviceprovider Service Management',
                      'error'
                    )
                    boolPermission = false
                  }
                }
              })
            })
          }
        }
      })
    })
    permission.forEach((datService) => {
      datService.privileges.forEach((privDatService) => {
        if (
          privDatService.value
            .toLowerCase()
            .includes('serviceprovider.product.create') ||
          privDatService.value
            .toLowerCase()
            .includes('serviceprovider.product.update')
        ) {
          if (privDatService.isAssigned) {
            permission.forEach((datProductService) => {
              datProductService.privileges.forEach((privProudctDatService) => {
                if (
                  privProudctDatService.value
                    .toLowerCase()
                    .includes('master.product.view')
                ) {
                  if (!privProudctDatService.isAssigned) {
                    toast.showToast(
                      'Please select View Service permission on Master Service Management',
                      'error'
                    )
                    boolPermission = false
                  }
                }
              })
            })
          }
        }
      })
    })
    return boolPermission
  }
  useEffect(() => {
    if (props?.details) {
      getRoleByIdPrivilegesApi(props?.details?.roleId)
    }
  }, [])
  return (
    <Box role="presentation">
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <List>
        <div style={{ padding: '0px 30px' }}>
          <form onSubmit={formik.handleSubmit}>
            <div style={{ paddingBottom: ' 100px' }}>
              <Grid container columnSpacing={4} rowSpacing={3}>
                <Grid item md={6} sm={6} xs={6}>
                  {requiredLabel('Role Name')}
                  <TextField
                    id="roleName"
                    name="roleName"
                    onChange={formik.handleChange}
                    value={formik.values.roleName}
                    fullWidth
                    InputProps={{
                      style: {
                        height: '35px',
                      },
                    }}
                  />
                  <div
                    style={{
                      color: 'red',
                      position: 'absolute',
                      marginBottom: '5px',
                    }}
                  >
                    {formik.errors.roleName && formik.errors.roleName}
                  </div>
                </Grid>
              </Grid>
              <h4>
                <div style={{ display: 'flex' }}>
                  <div>Choose Permissions</div>
                </div>
              </h4>

              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                style={{
                  height: 'calc(90vh - 280px)',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {loader ? (
                  <div
                    style={{
                      padding: '5px 10px 0 20px',
                    }}
                  >
                    <SkeletonTableLoader></SkeletonTableLoader>
                  </div>
                ) : (
                  <>
                    {permissionList?.privileges?.map((rol, index1) => {
                      return <>
                        {rol.groupName.includes('Dashboard') ? (
                          ''
                        ) : (
                          <Grid key={index1 + 'grid'}>
                            <FormControlLabel
                              style={{
                                borderTop: '1px solid silver',
                                width: '100%',
                              }}
                              label={rol?.groupName.replace(
                                /ServiceProvider/g,
                                'Service Provider'
                              )}
                              key={index1 + 'formControl'}
                              control={
                                <Checkbox
                                  disabled={rol?.groupName.includes(
                                    'Company'
                                  )}
                                  key={index1 + 'checkbox'}
                                  indeterminate={
                                    rol.privileges.filter(
                                      (is) => is.isAssigned
                                    ).length != rol.privileges.length &&
                                    rol.privileges.filter(
                                      (is) => is.isAssigned
                                    ).length != 0
                                  }
                                  checked={
                                    rol.privileges.filter(
                                      (is) => is.isAssigned
                                    ).length == rol.privileges.length
                                  }
                                />
                              }
                              onChange={(event) =>
                                handleAllPrivileges(event, rol, index1)
                              }
                            />

                            <Box
                              key={index1 + '_' + rol.groupName + 'box'}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                ml: 3,
                              }}
                            >
                              {rol?.privileges?.map((childRol, index2) => {
                              if(childRol.id == SERVICE_MANAGEMENT_CREATE_SERVICE_ID &&  commonState.currentCompanyDetails?.companyLevel ==
                                      COMPANY_LEVELS.customer && !commonState.currentCompanyDetails.directCustomer) return null //Should not allowed to use create service functionality for ReSeller Customer
                                return (
                                  <Box
                                    key={
                                      childRol.id +
                                      '_' +
                                      index1 +
                                      '_' +
                                      index2 +
                                      'box'
                                    }
                                  >
                                    <FormControlLabel
                                      key={
                                        childRol.id +
                                        '_' +
                                        index1 +
                                        '_' +
                                        index2
                                      }
                                      label={childRol?.displayName.replace(
                                        /ServiceProvider/g,
                                        'Service Provider'
                                      )}
                                      name={childRol.id}
                                      control={
                                        <Checkbox
                                          disabled={childRol?.value.includes(
                                            'company.view'
                                          )}
                                          key={
                                            childRol.id +
                                            '_' +
                                            index1 +
                                            '_' +
                                            index2 +
                                            'checkbox'
                                          }
                                          checked={childRol?.isAssigned}
                                          onChange={(event) =>
                                            handlePrivileges(
                                              event,
                                              index1,
                                              index2,
                                              childRol
                                            )
                                          }
                                        />
                                      }
                                    />
                                  </Box>
                                );
                              })}
                            </Box>
                          </Grid>
                        )}
                      </>;
                    })}
                  </>
                )}
              </Grid>
            </div>

            <Box
              style={{
                position: 'fixed',
                bottom: '0px',
                paddingTop: '30px',
                paddingBottom: '30px',
                background: 'white',
                display: 'flex',
              }}
            >
              <Button
                disabled={!formik.isValid}
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                size="large"
              >
                Update Role
              </Button>
              <div style={{ marginLeft: '20px' }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    props.toggleDrawer('right', false, '', '', false)
                  }
                  disableElevation
                  size="large"
                >
                  Cancel
                </Button>
              </div>
            </Box>
          </form>
        </div>
      </List>
    </Box>
  );
}

export default EditRole
