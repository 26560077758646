import React, { useEffect, useState, useCallback } from 'react'
import {
  Modal,
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Grid,
  Stack,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@mui/material'
import jwt_decode from 'jwt-decode'
import BasicAndDefault from './steppers/BasicAndDefault'
import RecordingSettings from './steppers/RecordingSettings'
import EmergencySettings from './steppers/EmergencySettings'
import AzureSettings from './steppers/AzureSettings'
import {
  serviceSliceState,
  setDomainData,
  setSummaryDetails,
} from '../../../../../../store/slice/serviceSlice'
import { useSelector } from 'react-redux'
import { useCommonRedux } from '../../../../../../store/middlewares/CommonRedux'
import {
  executeWithTryCatch,
  formatString,
  formatStringDomain,
  normalizeApiResponse,
} from '../../../../../../helpers/HelperFunctions'
import { useToastContext } from '../../../../../../App'
import { useHomeRedux } from '../../../../Home'
import {
  api_MaxUCloudGetDomains,
  createDomain,
  updateDomain,
} from '../../../../../../api/services/MaxCloudAPI'
import { TOAST_MESSAGES } from '../../../../../../helpers/Constants'
import CancelIcon from '@mui/icons-material/Cancel'
import { useSearchParams } from 'react-router-dom'
import { orderingServiceCoreViewSummaryGet } from '../../../../../../api/orders/ordersAPI'

const steps = [
  {
    title: 'Basic Setup',
    description: 'about....',
  },
  {
    title: 'Recording Settings',
    description: 'about....',
  },
  {
    title: 'Emergency Settings',
    description: 'about....',
  },
  {
    title: 'Azure Settings (Optional)',
    description: 'about....',
  },
]

const DomainCreationModal = ({
  open,
  handleClose,
  goToServiceFunction,
  formMode = 'create',
}) => {
  const [searchParams] = useSearchParams()
  const [activeStep, setActiveStep] = useState(0)
  const serviceState = useSelector(serviceSliceState)
  const { toast } = useToastContext()
  const { dispatch, state: commonState } = useCommonRedux()
  const { state: homeState } = useHomeRedux()
  const [errors, setErrors] = useState({})
  const [btnLoading, setBtnLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const VMTranscriptionOrder =
    serviceState?.summaryDetails?.RevioOrderInfo?.find(
      (order) => order.Description === 'MaxCloud UC2 VM Transcription'
    )
  const recordingSizeOrder =
    ((serviceState?.summaryDetails?.RevioOrderInfo?.find(
      (order) => order.Description === 'MaxCloud UC2 Call Recording'
    )?.Quantity ?? 0) +
      (serviceState?.summaryDetails?.RevioOrderInfo?.find(
        (order) =>
          order.Description === 'MaxCloud UC2 Call Recording Additional Storage'
      )?.Quantity ?? 0)) *
    20
  const [formData, setFormData] = useState({
    domain: formatStringDomain(commonState.currentCompanyDetails?.name),
    reseller: commonState.currentCompanyDetails?.directCustomer
      ? 'ALTIGEN'
      : formatString(commonState.currentCompanyDetails?.parentName),
    'time-zone': '',
    'area-code': '',
    'caller-id-name': commonState?.currentCompanyDetails?.name?.toUpperCase(),
    'is-domain-locked': 'no',
    SizeLimit: recordingSizeOrder
      ? recordingSizeOrder
      : (serviceState?.domainData?.['max-recording-size-gb'] ?? 0) /
        1000 /
        1000,
    'recording-configuration': 'no',
    AgeLimit: 0,
    'enable-call-recording': Boolean(
      recordingSizeOrder ||
        (serviceState?.domainData?.['max-recording-size-gb'] ?? 0) / 1000 / 1000
    ),
    'expand-storage': Boolean(
      (recordingSizeOrder ||
        (serviceState?.domainData?.['max-recording-size-gb'] ?? 0) /
          1000 /
          1000) > 20
    ),
    'enable-retention-length': false,
    notificationEmail: [],
    extensionDigits: serviceState?.domainData?.extensionDigits,
    'voicemail-transcription-enabled':
      VMTranscriptionOrder && VMTranscriptionOrder != 'no' ? 'Deepgram' : 'no',
    SDCMaxUsers: serviceState?.summaryDetails?.RevioOrderInfo.filter(
      (item) => item.PackageID !== null
    )
      .reduce((sum, item) => sum + (item?.Quantity || 0), 0)
      .toString(),
    'caller-id-number-emergency':
      serviceState?.domainData?.['caller-id-number-emergency'] === 0
        ? '0000000000'
        : serviceState?.domainData?.['caller-id-number-emergency'],
    'caller-id-number':
      serviceState?.domainData?.['caller-id-number'] === 0
        ? '0000000000'
        : serviceState?.domainData?.['caller-id-number'],
  })
  const getModifiedValue = (name, value) => {
    if (name == 'extensionDigits') {
      return parseInt(value)
    } else if (name === 'callerName') {
      return value.toUpperCase()
    }
    return value
  }

  const handleChange = useCallback((e) => {
    const { name, value } = e.target
    setFormData((prev) => ({
      ...prev,
      [name]: getModifiedValue(name, value),
    }))
    setErrors((prev) => ({ ...prev, [name]: value ? '' : prev[name] }))
  }, [])
  const handleChangeOptions = (event, newValue) => {
    const name = event?.target?.name

    if (!name) return

    setFormData((prev) => ({
      ...prev,
      [name]: newValue?.value || '',
    }))
  }

  const handleSubmit = async (item) => {
    executeWithTryCatch(async () => {
      const isSkip = item === 'skip'
      const isSubmit = item === 'submit'
      if (isSkip) setBtnLoading(true)
      if (isSubmit) setLoading(true)

      const payload = {
        ...formData,
        notificationEmail: [
          ...new Set([
            'tsodistributiongroup@altigen.com',
            ...(formData?.notificationEmail || []),
          ]),
        ],
        ...(formData?.['enable-call-recording']
          ? {
              quotaInputModel: {
                Domain: formData?.domain,
                SizeLimit: (Number(formData?.SizeLimit) || 0) * 1_000_000,
                AgeLimit: formData?.AgeLimit,
              },
            }
          : {}),
      }
      const handleResponse = async (response, successMessage) => {
        if (response) {
          handleClose()
          setBtnLoading(false)
          setLoading(false)
          toast.showToast(successMessage)
        }
      }

      if (formMode.toLowerCase() === 'edit') {
        const response = await updateDomain(getCompanyId(), payload)
        await handleResponse(response, TOAST_MESSAGES.DomainUpdated)

        if (response) {
          setActiveStep(0)
          const responseForDomain = await api_MaxUCloudGetDomains(
            getCompanyId()
          )
          const orderResponse = await orderingServiceCoreViewSummaryGet(
            {
              companyId: getCompanyId(),
              OrderId: serviceState?.orderService?.find(
                (order) => order.productCode === 'CVUC'
              )?.orderId,
            },
            getCompanyId()
          )
          if (responseForDomain.status === 200) {
            dispatch(setDomainData(responseForDomain?.data?.Result))
          }
          if (orderResponse.status === 200) {
            dispatch(setSummaryDetails(orderResponse?.data?.Result))
          }
        }
      } else {
        const response = await createDomain(homeState?.companyId, payload)
        await handleResponse(response, TOAST_MESSAGES.DomainCreated)

        if (response) {
          localStorage.removeItem('OrderActivatedByMaxCloudUc')
          localStorage.removeItem('isAlreadyActivatedByMaxCloudUc')
          localStorage.removeItem('oid')
          localStorage.removeItem('ct')
        }
        goToServiceFunction('list')
        goToServiceFunction('CVUClist')
      }
    }, handleSaveError)
  }

  const handleSaveError = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
    setBtnLoading(false)
    setLoading(false)
  }

  const handleToggle = (name) => {
    setFormData((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const handleNext = (item) => {
    if (validateFields(activeStep)) {
      if (activeStep < steps.length - 1) {
        setActiveStep(activeStep + 1)
      }
    }
    if (activeStep === steps.length - 1) {
      handleSubmit(item)
    }
  }
  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1)
    }
  }

  const validateFields = (activeStep) => {
    let newErrors = {}

    switch (activeStep) {
      case 0:
        if (!formData?.['caller-id-name'])
          newErrors['caller-id-name'] = 'This field is required'
        if (
          !formData?.['caller-id-number'] ||
          formData?.['caller-id-number'].toString().length < 10
        )
          newErrors['caller-id-number'] = 'This field is required'
        if (
          !formData?.['area-code'] ||
          formData?.['area-code'].toString().length < 3
        )
          newErrors['area-code'] = 'This field is required'
        if (!formData?.['time-zone'])
          newErrors['time-zone'] = 'This field is required'
        if (!formData?.extensionDigits)
          newErrors['extensionDigits'] = 'This field is required'

        break

      case 1:
        if (formData?.['enable-retention-length']) {
          if (!formData?.['AgeLimit'])
            newErrors['AgeLimit'] = 'This field is required'
        }
        if (formData?.SizeLimit == 20) {
          setFormData((prev) => ({
            ...prev,
            'expand-storage': false,
          }))
        }
        break

      case 2:
        if (
          !formData?.['caller-id-number-emergency'] ||
          formData?.['caller-id-number-emergency'].toString().length < 10
        )
          newErrors['caller-id-number-emergency'] = 'This field is required'
        if (formData?.['notificationEmail'].length == 0)
          newErrors['notificationEmail'] = 'This field is required'
        break

      default:
        break
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0 // Return true if no errors
  }
  const getCompanyId = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      return companyId
    }
  }
  const STEPPERS = [
    <BasicAndDefault
      formData={formData}
      handleChange={handleChange}
      handleToggle={handleToggle}
      handleChangeOptions={handleChangeOptions}
      errors={errors}
      setFormData={setFormData}
      formMode={formMode}
    />,
    <RecordingSettings
      formData={formData}
      handleChange={handleChange}
      handleToggle={handleToggle}
      errors={errors}
      formMode={formMode}
    />,
    <EmergencySettings
      formData={formData}
      handleChange={handleChange}
      handleToggle={handleToggle}
      formMode={formMode}
      errors={errors}
    />,
    <AzureSettings
      formData={formData}
      handleChange={handleChange}
      handleToggle={handleToggle}
      getCompanyId={getCompanyId}
    />,
  ]
  useEffect(() => {
    if (formMode.toLowerCase() === 'edit') {
      setFormData({
        ...serviceState?.domainData,
        domain: formatStringDomain(serviceState?.domainData?.domain),
        reseller: formatString(serviceState?.domainData?.reseller),
        'area-code': serviceState?.domainData?.['area-code'],
        'caller-id-name':
          serviceState?.domainData?.['caller-id-name']?.toUpperCase(),
        'is-domain-locked': 'no',
        SizeLimit: recordingSizeOrder
          ? recordingSizeOrder
          : serviceState?.domainData?.['max-recording-size-gb'] / 1000 / 1000,
        'expand-storage': Boolean(
          (recordingSizeOrder ||
            (serviceState?.domainData?.['max-recording-size-gb'] ?? 0) /
              1000 /
              1000) > 20
        ),
        'enable-call-recording': Boolean(
          recordingSizeOrder ||
            (serviceState?.domainData?.['max-recording-size-gb'] ?? 0) /
              1000 /
              1000
        ),
        AgeLimit: serviceState?.domainData?.['max-retention-days'],
        systemExtensionsNotified: 'no',
        notificationPhone:
          serviceState?.domainData?.notificationEmergencies
            ?.filter((e) => e.EntityType === '2')
            ?.map((e) => e.EntityValue) ?? [],
        notificationEmail:
          serviceState?.domainData?.notificationEmergencies
            ?.filter(
              (e) =>
                e.EntityType === '1' &&
                e.EntityValue !== 'tsodistributiongroup@altigen.com'
            )
            ?.map((e) => e.EntityValue) ?? [],
        'voicemail-transcription-enabled':
          VMTranscriptionOrder && VMTranscriptionOrder != 'no'
            ? 'Deepgram'
            : 'no',
        SDCMaxUsers: serviceState?.summaryDetails?.RevioOrderInfo.filter(
          (item) => item.PackageID !== null
        )
          .reduce((sum, item) => sum + (item?.Quantity || 0), 0)
          .toString(),
        'caller-id-number-emergency':
          serviceState?.domainData?.['caller-id-number-emergency'] === 0
            ? '0000000000'
            : serviceState?.domainData?.['caller-id-number-emergency'],
        'caller-id-number':
          serviceState?.domainData?.['caller-id-number'] === 0
            ? '0000000000'
            : serviceState?.domainData?.['caller-id-number'],
      })
    }
  }, [formMode])

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          width: 850,
          height: '90vh',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: '#FFFFFF',
          borderRadius: 4, // Ensures rounded corners
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden', // Ensures children don’t break border radius
        }}
      >
        <Grid container sx={{ flex: 1, overflow: 'hidden' }}>
          {/* Sidebar */}
          <Grid
            item
            xs={3}
            sx={{
              backgroundColor: '#F6F7F9',
              p: 5,
              height: '100%', // Ensure it takes the full height of the container
            }}
          >
            <Typography variant="h6" fontWeight="bold" gutterBottom>
              Domain Setting
            </Typography>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label.title}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          {/* Main Content */}
          <Grid
            item
            xs={9}
            sx={{
              p: 5,
              height: '100%', // Ensure it takes the full height of the container
              overflowY: 'auto', // Force scrollbar
            }}
          >
            <Stack
              direction="row"
              spacing={1}
              mb={2}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ width: '100%' }}
              flexWrap={'nowrap'}
            >
              <Typography variant="h5">{steps[activeStep]?.title}</Typography>
              {/* Close Button */}
              <Tooltip title="Close">
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CancelIcon color="error" size="large" />
                </IconButton>
              </Tooltip>
            </Stack>

            {STEPPERS[activeStep]}
          </Grid>
        </Grid>

        {/* Footer Buttons */}
        <Stack
          direction="row"
          spacing={2}
          p={2}
          justifyContent="flex-end"
          sx={{
            backgroundColor: '#FFFFFF',
            borderTop: '1px solid #ddd',
          }}
        >
          {activeStep > 0 && (
            <Button variant="outlined" color="primary" onClick={handlePrevious}>
              Back
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleNext('skip')}
              disabled={serviceState?.configuration?.isAppConsented}
            >
              Skip & {formMode.toLowerCase() === 'edit' ? 'Update' : 'Submit'}{' '}
              {btnLoading && (
                <CircularProgress
                  size={20}
                  style={{
                    color: '#0984E3',
                    marginLeft: '5px',
                  }}
                />
              )}
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleNext('submit')}
            disabled={
              activeStep === steps.length - 1 &&
              !serviceState.configuration?.isAppConsented
            }
          >
            {activeStep === steps.length - 1 ? (
              <>
                {formMode.toLowerCase() === 'edit' ? 'Update' : 'Submit'}
                {loading && (
                  <CircularProgress size={20} sx={{ color: '#fff', ml: 1 }} />
                )}
              </>
            ) : (
              'Next'
            )}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}

export default DomainCreationModal
