import React from 'react'
import jwt_decode from 'jwt-decode'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { orderActivatedByMaxClouduc } from '../../../../../api/services/MaxCloudAPI'

export default function OrderActivatedByMaxCloudUc() {
  let isAuthenticated = false
  const location = useLocation()
  const navigate = useNavigate()
  const queryParams = new URLSearchParams(location.search)
  const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
  const ct = queryParams.get('ct')
  const oid = queryParams.get('oid')
  const companyId = queryParams.get('company_id')
  if (decodeToken) {
    const decoded = jwt_decode(decodeToken)
    isAuthenticated = decoded?.company_id != '' && decoded?.company_level != ''
    if (!isAuthenticated) {
      return <Navigate to="/login" state={{ from: location }} replace={true} />
    }
  }
  React.useEffect(() => {
    if (ct && oid) {
      localStorage.setItem('ProductCode', 'CVUC')
      activateOrderForMaxCloud()
    } else {
      localStorage.removeItem('OrderActivatedByMaxCloudUc')
    }
  }, [ct, oid])

  const activateOrderForMaxCloud = async () => {
    try {
      const response = await orderActivatedByMaxClouduc({ ct, oid, companyId})
      const isAlreadyActivatedText = '<h1>Your order is already Activated.</h1>'
      if (typeof response.data === 'string') {
        if (response.data.includes(isAlreadyActivatedText)) {
          localStorage.setItem('isAlreadyActivatedByMaxCloudUc', true)
        } else {
          localStorage.setItem('isAlreadyActivatedByMaxCloudUc', false)
        }
      }
      localStorage.setItem('OrderActivatedByMaxCloudUc', true)
      navigate('/', { replace: true })
    } catch (error) {
      localStorage.setItem('OrderActivatedByMaxCloudUc', false)
      navigate('/', { replace: true })
    }
  }
}
