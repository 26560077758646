import { styled, Typography } from "@mui/material";

export const BoldLabelText = styled(Typography)({
    fontSize: '1rem',
    fontWeight: 'bold',
})

export const TitleText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 'bold',
    fontsize: '12px',
    color: 'rgba(23,43,78,0.87) !important'
}))

export const CaptionText = styled(Typography)(({ theme }) => ({
    fontSize: '0.90rem',
    fontWeight: theme.typography.caption.fontWeight,
    color: theme.typography.caption.color,
    textAlign: 'justify'
}))

export const NoteText = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontStyle: 'italic',
    color: theme.typography.caption.color,
}))

export const IndentedText = styled(Typography)(({ indent }) => ({
    textIndent: `${indent}px`
}))