import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material'
import * as React from 'react'
import {
  masterContactAllApi,
  masterPostContactApi,
} from '../../../../api/master/masterAPI'
import jwt_decode from 'jwt-decode'
import { useEffect } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import { useFormik } from 'formik'
import LoadingSection from '../../../../common/components/loaders/loader'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  setContactDetails,
  settingsSliceState,
} from '../../../../store/slice/settingsSlice'
import { ContactSkeleton } from '../../../../common/components/loaders/skeletons/SettingsSkeleton'
import { useCommonRedux } from '../../../../store/middlewares/CommonRedux'
import {
  COMPANY_LEVELS,
  CONTACT_TYPE_ENUM,
  PERMISSIONS,
} from '../../../../helpers/Constants'
import {
  checkPermission,
  getCompanyLevel,
  normalizeApiResponse,
} from '../../../../helpers/HelperFunctions'
import { ContactFormValidation } from '../../../../validations/SettingsValidation'
import { setSkeletonLoading } from '../../../../store/slice/commonSlice'
import { useToastContext } from '../../../../App'

export function MasterContact(props) {
  const { dispatch, state } = useCommonRedux()
  const { toast } = useToastContext()
  const settingsState = useSelector(settingsSliceState)
  const [contactType, setContactType] = React.useState(1)
  const [responseDetails, setResponseDetails] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [showEdit, setShowEdit] = React.useState(false)
  const [submitLoader, setSubmitLoader] = React.useState(false)
  const [arrayContactType, setArrayContactType] = React.useState([])
  const [touched, setTouched] = React.useState(true)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const currentLevel = getCompanyLevel(
    searchParams.get('company_id'),
    location.pathname.slice(1)
  )

  const formik = useFormik({
    initialValues: {
      companyId: '',
      firstName: '',
      lastName: '',
      phone: '',
      phoneExtension: '',
      mobile: '',
      fax: '',
      email: '',
      contactType: 1,
      notes: '',
    },
    initialTouched: false,
    validationSchema: ContactFormValidation,
    onSubmit: (data) => {
      setSubmitLoader(true)
      const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
      const decoded = jwt_decode(decodeToken)
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      data.companyId = companyId
      data.contactType = contactType
      masterPostContactApi(
        data,
        getCompanyLevel(
          searchParams.get('company_id'),
          location.pathname.slice(1)
        )
      )
        .then((response) => {
          const result = response.data.result
          setSubmitLoader(false)
          setShowEdit(false)
          toast.showToast(response.data.message, 'success')
          const obj = {
            companyId: result?.companyId,
            firstName: result?.firstName,
            lastName: result?.lastName,
            phone: result?.phone,
            phoneExtension: result?.phoneExtension,
            mobile: result?.mobile,
            fax: result?.fax,
            email: result?.email,
            contactType: result?.contactType,
            notes: result?.notes,
          }
          const _responseDetails = responseDetails.find(
            (x) => x.contactType === contactType
          )
            ? responseDetails.map((x) =>
                x.contactType === contactType ? obj : x
              )
            : [...responseDetails, obj]

          setResponseDetails(_responseDetails)
          dispatch(setContactDetails(_responseDetails))
        })
        .catch((error) => {
          setSubmitLoader(false)
          toast.showToast(normalizeApiResponse(error)?.message, 'error')
        })
    },
  })

  const getMasterContactAllApi = (id) => {
    if (id) {
      dispatch(setSkeletonLoading(true))
      masterContactAllApi(id, currentLevel)
        .then((response) => {
          if (response.data.result) {
            setTouched(true)
            setResponseDetails(response.data.result)
            dispatch(setContactDetails(response.data.result))
            const contactObj = response.data.result.find(
              (filt) => filt.contactType == contactType
            )
            contactObj &&
              Object.keys(contactObj)?.length &&
              updateFormDetails(contactObj)
          }
          dispatch(setSkeletonLoading(false))
        })
        .catch((error) => {
          dispatch(setSkeletonLoading(false))
          toast.showToast(normalizeApiResponse(error)?.message, 'error')
        })
    }
  }

  const updateFormDetails = (contact) => {
    formik.setFieldValue('companyId', contact?.companyId)
    formik.setFieldValue('firstName', contact?.firstName)
    formik.setFieldValue('lastName', contact?.lastName)
    formik.setFieldValue('phone', contact?.phone)
    formik.setFieldValue('phoneExtension', contact?.phoneExtension)
    formik.setFieldValue('mobile', contact?.mobile)
    formik.setFieldValue('fax', contact?.fax)
    formik.setFieldValue('email', contact?.email)
    formik.setFieldValue('contactType', contact?.contactType)
    formik.setFieldValue('notes', contact?.notes)
  }
  const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
  const decoded = jwt_decode(decodeToken)

  const getDecodeValue = () => {
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getMasterContactAllApi(companyId)
    }
  }

  const showEditButton = (isEdit) => {
    if (!isEdit) {
      const contactObj = settingsState.contactDetails?.find(
        (filt) => filt.contactType == contactType
      )
      if (contactObj) {
        updateFormDetails(contactObj)
      } else {
        formik.resetForm({ values: { ...formik.initialValues, contactType } })
      }
    }
    setShowEdit(isEdit)
  }

  // Capture the window onload
  // useEffect(() => {
  //   window.onload = triggerOnReload()
  // }, [])

  useEffect(() => {
    getDecodeValue()
    formik.errors = {}
    setArrayContactType(
      Object.entries(CONTACT_TYPE_ENUM).filter((item) => {
        if (
          item[1] === CONTACT_TYPE_ENUM.Billing &&
          state.currentCompanyDetails?.companyLevel ===
            COMPANY_LEVELS.customer &&
          state.currentCompanyDetails.directCustomer === false
        )
          return false
        return true
      })
    )
  }, [searchParams.get('company_id')])

  useEffect(() => {
    formik.setTouched({}, touched)
  }, [touched, formik?.errors])

  const handleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setContactType(newAlignment)
      sessionStorage.setItem('innerTab', newAlignment)

      const contactObj = responseDetails.find(
        (filt) => filt.contactType == newAlignment
      )
      if (contactObj) {
        setTouched(true)
        updateFormDetails(contactObj)
      } else {
        formik.resetForm({
          values: { ...formik.initialValues, contactType: newAlignment },
        })
        setTouched(false)

        // formik.setErrors({}, false)
      }
      props.setShowBilling(newAlignment === 2) //billing
      event.preventDefault()
    }
  }

  //trigger reload to set navigation tab
  // const triggerOnReload = () => {
  //   const innerTab = Number(sessionStorage.getItem('innerTab'))
  //   setContactType(innerTab || contactType)
  // }

  const handleNumberInputChange = (event, field) => {
    const inputValue = event.target.value
    const numericValue = inputValue.replace(/\D/g, '') // Remove non-numeric characters

    // Update the form field value with the numeric value
    formik.setFieldValue(field, numericValue)
  }
  return (
    <>
      {submitLoader ? <LoadingSection></LoadingSection> : ''}
      <Paper elevation={6} style={{ minHeight: '500px' }} className="paper1">
        {state.isSkeletonLoading ? (
          <ContactSkeleton />
        ) : (
          <Box>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '0 0 0 20px',
              }}
            >
              <div style={{ fontSize: '16px', fontWeight: '600' }}>
                <ToggleButtonGroup
                  color="primary"
                  value={contactType}
                  exclusive
                  onChange={handleChange}
                >
                  {arrayContactType?.map((name, index) => (
                    <ToggleButton
                      key={index}
                      sx={{
                        fontSize: '16px',
                        textTransform: 'none',
                        height: '35px',
                      }}
                      disabled={showEdit}
                      size="large"
                      value={name[1]}
                    >
                      {' '}
                      {name[0]}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
              {checkPermission(
                [
                  PERMISSIONS.serviceproviderCompanyUpdate,
                  PERMISSIONS.resellerCompanyUpdate,
                  PERMISSIONS.customerCompanyUpdate,
                ],
                state.currentCompanyDetails
              ) || location.pathname.slice(1) == 'master' ? (
                !showEdit ? (
                  <div
                    style={{
                      padding: '5px 25px 0 0',
                    }}
                  >
                    <IconButton
                      onClick={() => showEditButton(true)}
                      aria-label="delete"
                      style={{
                        float: 'right',
                        borderRadius: 0,
                        fontSize: '15px',
                        marginTop: '-3px',
                      }}
                    >
                      <EditIcon
                        style={{
                          fontSize: '12px',
                          verticalAlign: 'middle',
                          margin: '0 0px 0 10px',
                        }}
                      />
                      Edit
                    </IconButton>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}
            </div>
            <Box>
              <form onSubmit={formik.handleSubmit}>
                <Box className="stepper-input-label-margin">
                  <label className="stepper-label-style">
                    Email &nbsp; <span style={{ color: 'red' }}>*</span>
                  </label>
                  <TextField
                    disabled={!showEdit}
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    value={formik.values.email || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                  <div style={{ color: 'red', position: 'absolute' }}>
                    {showEdit && formik.errors.email
                      ? formik.errors.email
                      : null}
                  </div>
                </Box>

                <Box className="stepper-input-label-margin">
                  <label className="stepper-label-style">
                    First name &nbsp; <span style={{ color: 'red' }}>*</span>
                  </label>
                  <TextField
                    disabled={!showEdit}
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    value={formik.values.firstName || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                  <div style={{ color: 'red', position: 'absolute' }}>
                    {showEdit && formik.errors.firstName
                      ? formik.errors.firstName
                      : null}
                  </div>
                </Box>
                <Box className="stepper-input-label-margin">
                  <label className="stepper-label-style">
                    Last Name &nbsp; <span style={{ color: 'red' }}>*</span>
                  </label>
                  <TextField
                    disabled={!showEdit}
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    value={formik.values.lastName || ''}
                    fullWidth
                    variant="outlined"
                    size="small"
                  />
                  <div style={{ color: 'red', position: 'absolute' }}>
                    {showEdit && formik.errors.lastName
                      ? formik.errors.lastName
                      : null}
                  </div>
                </Box>
                <Box className="stepper-input-label-margin">
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={10} xs={12}>
                      <label className="stepper-label-style">Mobile</label>
                      <TextField
                        disabled={!showEdit}
                        id="mobile"
                        InputProps={{
                          inputProps: {
                            minLength: 10,
                            maxLength: 10,
                          },
                        }}
                        name="mobile"
                        onChange={(e) => handleNumberInputChange(e, 'mobile')}
                        value={formik.values.mobile || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <div style={{ color: 'red', position: 'absolute' }}>
                        {showEdit && formik.errors.mobile
                          ? formik.errors.mobile
                          : null}
                      </div>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <label className="stepper-label-style">Fax</label>
                      <TextField
                        disabled={!showEdit}
                        id="fax"
                        name="fax"
                        InputProps={{
                          inputProps: {
                            minLength: 10,
                            maxLength: 10,
                          },
                        }}
                        onChange={(e) => handleNumberInputChange(e, 'fax')}
                        value={formik.values.fax || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box className="stepper-input-label-margin">
                  <Grid container spacing={2}>
                    <Grid item md={10} sm={10} xs={12}>
                      <label className="stepper-label-style">
                        Phone Number &nbsp;{' '}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <TextField
                        disabled={!showEdit}
                        id="phone"
                        name="phone"
                        InputProps={{
                          inputProps: {
                            minLength: 10,
                            maxLength: 10,
                          },
                        }}
                        onChange={(e) => handleNumberInputChange(e, 'phone')}
                        value={formik.values?.phone || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                      <div style={{ color: 'red', position: 'absolute' }}>
                        {showEdit && formik.errors.phone
                          ? formik.errors.phone
                          : null}
                      </div>
                    </Grid>
                    <Grid item md={2} sm={2} xs={12}>
                      <label className="stepper-label-style">Ext</label>
                      <TextField
                        disabled={!showEdit}
                        id="phoneExtension"
                        name="phoneExtension"
                        InputProps={{
                          inputProps: {
                            minLength: 20,
                            maxLength: 20,
                          },
                        }}
                        onChange={(e) =>
                          handleNumberInputChange(e, 'phoneExtension')
                        }
                        value={formik.values.phoneExtension || ''}
                        fullWidth
                        variant="outlined"
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </Box>
                {showEdit ? (
                  <Box
                    style={{
                      paddingTop: '10px',
                      paddingBottom: '5px',
                      background: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                    className="stepper-input-label-margin"
                  >
                    {checkPermission(
                      [
                        PERMISSIONS.serviceproviderCompanyUpdate,
                        PERMISSIONS.resellerCompanyUpdate,
                        PERMISSIONS.customerCompanyUpdate,
                      ],
                      state.currentCompanyDetails
                    ) || location.pathname.slice(1) == 'master' ? (
                      <Button
                        disabled={!(formik.isValid && formik.dirty)}
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="large"
                        style={{ margin: '0 5px' }}
                      >
                        Update
                      </Button>
                    ) : (
                      ''
                    )}
                    <Button
                      onClick={() => showEditButton(false)}
                      type="button"
                      variant="contained"
                      color="secondary"
                      size="large"
                      style={{ margin: '0 5px' }}
                    >
                      Cancel
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </form>
            </Box>
          </Box>
        )}
      </Paper>
    </>
  )
}

export default MasterContact
