import React from 'react'
import { Tooltip, IconButton, Typography, Box } from '@mui/material'
import { styled } from '@mui/system'
import { Cancel as CloseIcon } from '@mui/icons-material'
import { getNumberIconStatus } from '../../../helpers/HelperFunctions'
import { get } from 'lodash'

const StyledDrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
  backgroundImage:
    'linear-gradient(146deg, rgba(9, 132, 227, 1) 0%, rgba(151, 199, 237, 1) 100%)',
}))

const DrawerHeader = (props) => {
  const { header, onClick, status, ...drawerProps } = props

  return (
    <StyledDrawerHeader {...drawerProps}>
      <Typography variant="dialogHeader" noWrap align="left">
        {header} {status !== undefined ? getNumberIconStatus(status) : ''}
      </Typography>
      <Tooltip title="Close">
        <Box sx={{paddingRight: '10px'}}>
          <IconButton
            onClick={onClick}
            sx={{
              color: '#FFFFFF',
              width: '24px',
              height: '24px',
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Tooltip>
    </StyledDrawerHeader>
  )
}

export default DrawerHeader
