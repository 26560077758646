import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useFormik } from 'formik'
import {
  executeWithTryCatch,
  gettingCompanyId,
  isNullOrEmpty,
  isResponseSuccess,
  normalizeApiResponse,
  requiredLabel,
} from '../../../../../../../helpers/HelperFunctions'

import { useSelector } from 'react-redux'
import {
  serviceSliceState,
  setConfiguration,
} from '../../../../../../../store/slice/serviceSlice'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useSearchParams } from 'react-router-dom'
import GppGoodIcon from '@mui/icons-material/GppGood'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import EditIcon from '@mui/icons-material/Edit'
import { useHomeRedux } from '../../../../../Home'
import { useToastContext } from '../../../../../../../App'
import { postDRConfiguration } from '../../../../../../../api/post/postApi'
import { getCustomerConfiguration } from '../../../../../../../api/get/getApi'
import { TOAST_MESSAGES } from '../../../../../../../helpers/Constants'

const AzureSettings = ({ getCompanyId }) => {
  const serviceState = useSelector(serviceSliceState)
  const { toast } = useToastContext()
  const [staticPassword, setStaticPassword] = useState(
    serviceState.configuration?.username ? '*******' : ''
  )
  const [showEditButton, setShowEditButton] = useState(
    serviceState.configuration?.username !== null
  )
  const [btnLoading, setBtnLoading] = useState(false)
  const [isRefreshConfig, setIsRefreshConfig] = useState(false)
  const [showPassword, setshowPassword] = useState(false)
  const [isSaved, setIsSaved] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const { dispatch } = useHomeRedux()
  let intervalId = null

  const ValidationSchema = Yup.object().shape({
    userName: Yup.string().required('Microsoft Admin Account is required'),
    password: Yup.string().required('Password is required'),
    tenantId: Yup.string().required('Tenant ID is required'),
  })

  const initialValues = useMemo(
    () => ({
      userName: serviceState.configuration?.username
        ? serviceState.configuration?.username
        : '',
      password: serviceState.configuration?.username ? '' : staticPassword,
      tenantId: serviceState.configuration?.aadTenantId
        ? serviceState.configuration?.aadTenantId
        : '',
    }),
    [serviceState.configuration, staticPassword]
  )

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: ValidationSchema,
    validateOnChange: true,
    onSubmit: (data) => handleSave(data),
  })
  const handleEdit = () => {
    setShowEditButton(false)
    setStaticPassword('')
    formik.setFieldValue('password', '')
  }

  //conditions
  let isDisableSaveBtn =
    isNullOrEmpty(formik.values.password || staticPassword) ||
    isNullOrEmpty(formik.values.tenantId) ||
    isNullOrEmpty(formik.values.userName)

  const handleInputChange = (e) => {
    const { name, value } = e.target
    formik.setFieldValue(name, value) // Ensure Formik updates the field value
    setIsSaved(false)
    dispatch(setConfiguration({ ...serviceState.configuration, [name]: value }))
  }

  useEffect(() => {
    if (isRefreshConfig) {
      intervalId = setInterval(() => {
        executeWithTryCatch(getConfigurationDetails, handleGetConfigError)
      }, 3000)
    } else {
      clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [isRefreshConfig])

  const getConfigurationDetails = async () => {
    const response = await getCustomerConfiguration(gettingCompanyId())
    if (isResponseSuccess(response)) {
      const isAppConsented = response.data.result.isAppConsented
      if (isAppConsented) {
        dispatch(setConfiguration(response.data.result))
        setIsRefreshConfig(false)
        clearInterval(intervalId)
        toast.showToast(TOAST_MESSAGES.PermissionGranted, 'success')
      }
      if (isAppConsented === null) return

      if (isAppConsented === false) {
        setIsRefreshConfig(false)
        clearInterval(intervalId)
        toast.showToast(TOAST_MESSAGES.PermissionDeclined, 'error')
      }
    }
  }
  const handleGetConfigError = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
    setIsRefreshConfig(false)
    clearInterval(intervalId)
  }
  const handleSave = async (values) => {
    executeWithTryCatch(async () => {
      setBtnLoading(true)
      setIsSaved(true)
      const payload = {
        ...values,
        companyId: getCompanyId(),
        sbcPrefix: serviceState.configuration?.sbcPrefix,
      }
      setIsChecked(false)
      setStaticPassword('*******')
      const response = await postDRConfiguration(payload)
      if (response) {
        setShowEditButton(true)
        setshowPassword(false)
        dispatch(setConfiguration(response?.data.result))
        const configurationResponse = await getCustomerConfiguration(
          gettingCompanyId()
        )
        if (configurationResponse) {
          dispatch(
            setConfiguration({
              ...configurationResponse.data.result,
              userName: configurationResponse.data.result.username,
              aadTenantId: configurationResponse.data.result.aadTenantId,
            })
          )
          setIsRefreshConfig(true)
          window.open(configurationResponse.data.result?.consentUrl, '_blank')
        }
      }
      setBtnLoading(false)
      formik.setFieldValue('password', '*')
    }, handleSaveError)
  }

  const handleSaveError = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
    setBtnLoading(false)
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <FormControl fullWidth margin="normal">
              <Typography>
                {requiredLabel('Microsoft Admin Account', true)}{' '}
              </Typography>
              <TextField
                name="userName"
                value={formik.values.userName}
                onChange={handleInputChange}
                fullWidth
                size="small"
                error={
                  formik.touched.userName && Boolean(formik.errors.userName)
                }
                onBlur={formik.handleBlur}
                helperText={formik.touched.userName && formik.errors.userName}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}></Grid>

          <Grid item xs={8}>
            <FormControl fullWidth margin="normal">
              <Typography>{requiredLabel('Password', true)} </Typography>
              <TextField
                name="password"
                disabled={showEditButton}
                placeholder={!showEditButton ? '' : staticPassword || '*******'}
                type={showPassword ? 'text' : 'password'}
                value={showEditButton ? '' : formik.values.password}
                onChange={handleInputChange}
                fullWidth
                size="small"
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                onBlur={formik.handleBlur}
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {formik.values?.password?.length > 0 &&
                      serviceState.configuration?.password !== null ? (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setshowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      ) : null}

                      {(serviceState.configuration?.username &&
                        formik.values?.password?.length == 0) ||
                      isSaved ? (
                        <IconButton
                          onClick={() => handleEdit()}
                          aria-label="edit"
                          style={{
                            float: 'end',
                            borderRadius: 0,
                            fontSize: '20px',
                          }}
                        >
                          <EditIcon
                            style={{
                              fontSize: '15px',
                              verticalAlign: 'end',
                              margin: '0 0px 0 0px',
                            }}
                          />
                        </IconButton>
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}></Grid>

          <Grid item xs={8}>
            <FormControl fullWidth margin="normal">
              <Typography>{requiredLabel('Tenant ID', true)}</Typography>
              <TextField
                name="tenantId"
                value={formik.values.tenantId}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                fullWidth
                size="small"
                error={
                  formik.touched.tenantId && Boolean(formik.errors.tenantId)
                }
                helperText={formik.touched.tenantId && formik.errors.tenantId}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}></Grid>

          <Grid item xs={8}>
            <Button
              variant="contained"
              type="submit"
              disabled={
                !isChecked
                  ? serviceState.configuration?.isAppConsented ||
                    isDisableSaveBtn ||
                    isSaved
                  : isDisableSaveBtn || false
              }
              onClick={() => {
                handleSave(formik.values)
              }}
            >
              Authenticate{' '}
              {btnLoading && (
                <CircularProgress
                  size={25}
                  style={{
                    color: '#0984E3',
                    marginLeft: '5px',
                  }}
                />
              )}
            </Button>
            <Tooltip title="Grant Permission">
              <IconButton
                disabled={serviceState.configuration?.isAppConsented}
                sx={{ marginLeft: '10px' }}
              >
                <GppGoodIcon
                  color={
                    serviceState.configuration?.isAppConsented
                      ? 'success'
                      : 'default'
                  }
                  sx={{ fontSize: 30 }}
                />
              </IconButton>
            </Tooltip>
          </Grid> {serviceState.configuration?.isAppConsented &&
          <Grid item xs={10} flex={1}>
            <Box display="flex" alignItems="center">
              <Checkbox
                color="primary"
                checked={isChecked}
                onClick={() => setIsChecked(!isChecked)}
              />
              <FormHelperText sx={{ marginLeft: '8px' }}>
                The Authentication is already done. Do you want to
                reauthenticate?
              </FormHelperText>
            </Box>
          </Grid> }
          <Grid item xs={10} flex={1}>
            <FormHelperText sx={{ marginTop: '10px' }}>
              Skip & Submit if you don't have a Microsoft account.
            </FormHelperText>
          </Grid>
        </Grid>
      </form>
      {/* )}
      </Formik> */}
    </>
  )
}

export default AzureSettings
