import React, { useEffect, useState } from 'react'
import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Autocomplete,
  Popper,
} from '@mui/material'
import TagInput from '../../../../../../../common/components/tags-input/TagsInput'
import NumberField from '../../../../../../../common/components/numberfield/Numberfield'
import {
  executeWithTryCatch,
  normalizeApiResponse,
  requiredLabel,
} from '../../../../../../../helpers/HelperFunctions'
import styled from '@emotion/styled'
import {
  serviceSliceState,
  setViaPhoneOptions,
} from '../../../../../../../store/slice/serviceSlice'
import { useToastContext } from '../../../../../../../App'
import { useCommonRedux } from '../../../../../../../store/middlewares/CommonRedux'
import { getNetSapiensUsersForDomainUpdate } from '../../../../../../../api/services/MaxCloudAPI'
import jwt_decode from 'jwt-decode'
import { useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Dropdown from '../../../../../../../common/components/dropdown/Dropdown'

const EmergencySettings = ({ formData, handleChange, formMode, errors }) => {
  const StyledPopper = styled(Popper)({
    zIndex: 1300, // Ensure it's above other elements
    '& .MuiAutocomplete-paper': {
      maxHeight: 150, // Set max height for the dropdown
      overflowY: 'auto',
    },
    '& .MuiAutocomplete-listbox': {
      maxHeight: 150, // Ensure list doesn't overflow
      overflowY: 'auto',
    },
  })
  const serviceState = useSelector(serviceSliceState)
  const { toast } = useToastContext()
  const { dispatch } = useCommonRedux()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    executeWithTryCatch(getBasicDetails, handleGetBasicDetailsError)
  }, [])
  const [searchParams] = useSearchParams()

  const getBasicDetails = async () => {
    setLoading(true)
    const response = await getNetSapiensUsersForDomainUpdate(
      getCompanyId(),
      serviceState?.domainData?.domain?.trim()
    )
    if (response) {
      const viaPhoneOptions = (response?.data?.Result ?? []).map((e) => ({
        name: e.Extension,
        value: e.Extension,
      }))
      dispatch(setViaPhoneOptions(viaPhoneOptions))
      setLoading(false)
    }
  }
  const handleGetBasicDetailsError = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
    setLoading(false)
  }

  const getCompanyId = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      return companyId
    }
  }

  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>
              {requiredLabel('Emergency Caller ID', true)}
            </Typography>
            {formMode === 'edit' ? (
              <Dropdown
                options={serviceState?.callerIds}
                getOptionLabel={(opt) => opt.name}
                name="caller-id-number-emergency"
                value={{
                  name:
                    formData?.['caller-id-number-emergency'] === 0
                      ? '0000000000'
                      : formData?.['caller-id-number-emergency'],
                  value:
                    formData?.['caller-id-number-emergency'] === 0
                      ? '0000000000'
                      : formData?.['caller-id-number-emergency'],
                }}
                onChange={(e, value, reason) => {
                  handleChange({
                    target: {
                      name: 'caller-id-number-emergency',
                      value: value.value,
                    },
                  })
                }}
                fullWidth
                maxDigits={10}
                size={'small'}
                errorMessage={'Minimum 10 digits required'}
                error={!!errors?.['caller-id-number']}
                helperText={errors?.['caller-id-number'] || ''}
              />
            ) : (
              <NumberField
                name="caller-id-number-emergency"
                value={formData?.['caller-id-number-emergency']}
                onChange={handleChange}
                disabled={
                  formData?.['caller-id-number-emergency'] === '0000000000'
                }
                fullWidth
                maxDigits={10}
                size={'small'}
                errorMessage={'Minimum 10 digits required'}
                error={!!errors?.['caller-id-number-emergency']}
                helperText={errors?.['caller-id-number-emergency'] || ''}
              />
            )}
          </FormControl>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <FormControl fullWidth margin="normal">
            <Typography>{requiredLabel('Notify Via Email', true)}</Typography>
            <TagInput
              onValidate={(val) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val)}
              fullWidth
              name="notificationEmail"
              value={formData.notificationEmail || ''}
              size="small"
              onChange={handleChange}
              isEmailEmpty={!!errors?.['notificationEmail']}
              error={!!errors?.['notificationEmail']}
            />
          </FormControl>
        </Grid>
        {formMode.toLowerCase() === 'edit' ? (
          <>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                Would you like any system extensions to be notified?
              </Typography>

              <Autocomplete
                multiple
                limitTags={2}
                name="notificationPhone"
                value={formData?.notificationPhone || []}
                options={(serviceState?.viaPhoneOption ?? []).filter(
                  (option) =>
                    !formData?.notificationPhone?.some(
                      (selected) =>
                        selected ===
                        (typeof option === 'string' ? option : option.name)
                    )
                )}
                filterSelectedOptions
                PopperComponent={(props) => <StyledPopper {...props} />}
                getOptionLabel={(option) =>
                  typeof option === 'string' ? option : option?.name || ''
                }
                filterOptions={(options, state) =>
                  options.filter(
                    (option) =>
                      !state.inputValue ||
                      option?.name
                        .toLowerCase()
                        .includes(state.inputValue.toLowerCase())
                  )
                }
                onChange={(event, newValue) =>
                  handleChange({
                    target: {
                      name: 'notificationPhone',
                      value: newValue.map((item) =>
                        typeof item === 'string' ? item : item.name
                      ),
                    },
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={loading ? 'Loading Phone...' : 'Select Phone'}
                    size="small"
                    sx={{ marginTop: 2 }}
                  />
                )}
              />
            </Grid>{' '}
          </>
        ) : null}
      </Grid>
    </>
  )
}

export default EmergencySettings
