import React, {
  useEffect,
  Suspense,
  createContext,
  useContext,
  useCallback,
} from 'react'

import { Container, Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import { genericDetailsApi } from '../../../api/get/getApi.js'
import { useSearchParams } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import ServiceProductCard from '../../../common/components/cards/serviceProductCard.jsx'
import SkeletonTableLoader from '../../../common/components/loaders/skeletonLoader.js'
import IconButton from '@mui/material/IconButton'
import CancelIcon from '@mui/icons-material/Cancel'
import { orderGetAllById } from '../../../api/orders/ordersAPI.js'
import NoDataFoundCard from '../../../common/components/cards/noDataFoundCard.jsx'
import {
  setDomainData,
  setIsOrderActivatedView,
  setIsOrderActivatedViewByMaxCloud,
  setPendingOrderStatus,
} from '../../../store/slice/serviceSlice.js'
import { useSelector } from 'react-redux'
import {
  resetRevService,
  resetServiceAddress,
  resetSipTrunk,
  serviceSliceState,
  setExistingAddress,
  setIsShowActions,
  setOrderService,
  setSelectedOrderService,
  setSelectedServiceProducts,
  setShowWizard,
  setShowWizardName,
  setSipAddressId,
  setSubscribedStates,
  setTeamsBundleList,
} from '../../../store/slice/serviceSlice.js'
import {
  setCurrentCompanyDetails,
  setSelectedProducts,
} from '../../../store/slice/commonSlice.js'
import { api_getSipExistAddress } from '../../../api/services/SIPTrunkingAPI.js'
import {
  ORDER_STATUS_ENUM,
  PRODUCT_CODES,
  PRODUCT_NAMES,
  SERVICE_PRODUCT_CODES,
  SERVICES,
} from '../../../helpers/Constants.js'
import {
  executeWithTryCatch,
  getCompanyLevel,
  normalizeApiResponse,
  removeDuplicates,
} from '../../../helpers/HelperFunctions.js'
import { useToastContext } from '../../../App.js'

const SIPTrunk = React.lazy(() => import('./sip-trunking/SIPTrunk.js'))
const MaxCloudUC = React.lazy(() => import('./max-cloud-uc/MaxCloudUC.js'))
const DirectRoutingPage = React.lazy(() =>
  import('./direct-routing/DirectRouting.js')
)
import './Service.css'
import { useCommonRedux } from '../../../store/middlewares/CommonRedux.js'
import { history } from '../../../common/components/router/HistoryRouter.jsx'
import { settingsSliceState } from '../../../store/slice/settingsSlice.js'
import OrderActivatedView from '../services/common/components/OrderActivatedView.jsx'
import OrderActivatedViewByMaxCloudUc from './max-cloud-uc/components/OrderActivatedViewByMaxCloudUc.jsx'
import { api_MaxUCloudGetDomains } from '../../../api/services/MaxCloudAPI.js'

// Create a context for Redux
const ServiceReduxContext = createContext()
export const useServiceRedux = () => useContext(ServiceReduxContext)

function ServicesProduct(props) {
  const { toast } = useToastContext()
  const { dispatch, state: commonState } = useCommonRedux()
  const state = useSelector(serviceSliceState)
  const settingsState = useSelector(settingsSliceState)
  const [services, setServices] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const [loadListBoolean, setLoadListBoolean] = React.useState(false)
  const [DraftedServices, setDraftedServices] = React.useState([])
  const [subscribedServices, setSubscribedServices] = React.useState(null)
  const [availableServices, setAvailableServices] = React.useState(null)
  const OrderActivated = localStorage.getItem('OrderActivated')
  const ProductCode = localStorage.getItem('ProductCode')
  const OrderActivatedByMaxCloudUc = localStorage.getItem(
    'OrderActivatedByMaxCloudUc'
  )

  const [searchParams] = useSearchParams()

  const genericDetails = async (id) => {
    try {
      setLoader(true)
      let details
      const detailsResponse = await genericDetailsApi(
        id,
        location.pathname.slice(1)
      )
      details = detailsResponse.data.result
      const selectedProducts = details.products
      const sipProductMaxLocationList = selectedProducts
        .filter((product) => product.productCode === PRODUCT_CODES.sipTrunking)
        ?.map((max) => max.maxLocationAllowed)
      const maxSipLocation =
        sipProductMaxLocationList.length > 0
          ? Math.max(...sipProductMaxLocationList)
          : 0
      dispatch(setSelectedProducts(selectedProducts))
      dispatch(setCurrentCompanyDetails(details))
      const isService = location.search.includes('service')
      await getAvailableAndSubscribedServices(
        selectedProducts,
        id,
        maxSipLocation
      ) // pass maxSipLocation as parameters to child methods
      if (isService)
        await viewOrder(searchParams.get('service'), subscribedServices)

      setLoader(false)
    } catch (error) {
      setLoader(false)
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }

  const getAvailableAndSubscribedServices = async (
    selectedProducts,
    id,
    maxSipLocation
  ) => {
    const resp = await orderGetAllById(
      { companyId: id, orderTypeId: 1 },
      getCompanyLevel(
        searchParams.get('company_id'),
        location.pathname.slice(1)
      )
    )
    dispatch(setOrderService(resp.data.result))
    const _services = await getCustomizedServices(
      selectedProducts,
      resp.data.result
    )
    const existingAddress = selectedProducts?.some(
      (o) => o.productCode === PRODUCT_CODES.sipTrunking
    )
      ? await getExistAddress(id, resp.data.result)
      : []
    const availableOrders = await getAvailableServices(
      _services,
      selectedProducts,
      existingAddress,
      resp.data.result,
      maxSipLocation
    )
    const subscribedOrders = await getSubscribedServices(
      _services,
      resp.data.result,
      existingAddress
    )

    setAvailableServices(availableOrders)
    setSubscribedServices(subscribedOrders)
  }

  const viewOrder = async (service, orders) => {
    let order = {}
    switch (Number(service || 0)) {
      case SERVICES.SipTrunking:
        const orderId = searchParams.get('order')
        order = orders.find(
          (o) =>
            o.productCode == SERVICE_PRODUCT_CODES.SipTrunking &&
            o.id == orderId
        )
        goToServiceFunction(order)
        break
      case SERVICES.DirectRouting:
        order = orders.find(
          (o) => o.productCode == SERVICE_PRODUCT_CODES.DirectRouting
        )
        goToServiceFunction(order)
        break
      case SERVICES.MaxCloudCoreView:
        order = orders.find(
          (o) => o.productCode == SERVICE_PRODUCT_CODES.MaxCloudCoreView
        )
        goToServiceFunction(order)
        break
      default:
        break
    }
  }

  const getCustomizedServices = async (allProducts, orderService) => {
    const hasCheckedKey = allProducts?.some((obj) =>
      Object.prototype.hasOwnProperty.call(obj, 'checked')
    )
    const _allProducts = hasCheckedKey
      ? allProducts?.filter((o) => o.checked)
      : allProducts
    const all = await _allProducts?.map((res) => {
      return {
        id: res.id,
        name: res.name,
        description: res.description,
        iconUrl: res.iconUrl,
        productCode: res.productCode,
        sipAddressId: res.sipAddressId,
        ordered: false,
      }
    })
    const checkedArray = await all?.map((item) => {
      let index = orderService?.find(
        (ser) => ser.productCode === item.productCode
      )
      if (index) {
        item.ordered = true
        item.orderId = index.orderId
        item.state = index.state
        item.orderStatus = index.status
        item.orderTypeId = index.orderTypeId
        item.sipAddressId = index.sipAddressId
      }
      return item
    })
    setServices(checkedArray)

    if (orderService?.length) {
      const subscribedStates = []
      await orderService?.forEach((service, index) => {
        if (service.productCode === PRODUCT_CODES.sipTrunking) {
          subscribedStates.push(service.state)
        }
      })
      dispatch(setSubscribedStates(subscribedStates))
    }
    return checkedArray
  }

  const getDecodeValue = async () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      await genericDetails(companyId)
    }
  }
  useEffect(() => {
    if (OrderActivatedByMaxCloudUc || null) {
      if (JSON.parse(OrderActivatedByMaxCloudUc || null)) {
        dispatch(setIsOrderActivatedViewByMaxCloud(true))
      } else {
        dispatch(setIsOrderActivatedViewByMaxCloud(false))
      }
    } else {
      if (JSON.parse(OrderActivated || null)) {
        dispatch(setIsOrderActivatedView(true))
      } else {
        dispatch(setIsOrderActivatedView(false))
      }
    }
  }, [OrderActivated, OrderActivatedByMaxCloudUc])

  useEffect(() => {
    if (state.isOrderSubmitted) {
      dispatch(setSipAddressId(0))
      dispatch(resetSipTrunk())
      dispatch(resetRevService())
    }
    getDecodeValue()

    if (
      state.configuration?.isAppConsented &&
      state.configuration?.aadTenantId
    ) {
      dispatch(setIsOrderActivatedView(false))
    }
  }, [state.isOrderSubmitted, state.isOrderActivatedView])

  const getExistAddress = async (companyId, orderService) => {
    try {
      const response = await api_getSipExistAddress(companyId)
      dispatch(setExistingAddress(response.data.Result))

      let drafted
      if (orderService?.length)
        drafted = response.data.Result.filter((o) =>
          orderService?.some((s) => s.sipAddressId != o.Id)
        )
      else drafted = response.data.Result

      const _drafted = drafted.map((service) => ({
        id: service?.Id,
        name: `SIP Trunking_${service.SipAddressEntity?.SipAddressName}`,
        description: service?.description,
        iconUrl: service?.iconUrl,
        productCode: PRODUCT_CODES.sipTrunking,
        sipAddressId: service?.Id,
        orderStatus: ORDER_STATUS_ENUM.Draft,
        ordered: false,
      }))

      setDraftedServices(_drafted)
      setLoadListBoolean(false)
      return response.data.Result
    } catch (error) {
      setLoader(false)
      setLoadListBoolean(false)
      toast.showToast(normalizeApiResponse(error)?.message, 'error')
    }
  }
  const goToServiceFunction = async (items, isNew) => {
    if (items == 'DRlist') {
      items =
        subscribedServices?.find((item) => item.productCode === 'DR') || null
    }

    if (items == 'CVUClist') {
      items =
        subscribedServices?.find((item) => item.productCode === 'CVUC') || null
    }
    if (items.productCode == 'CVUC') {
      if (state.domainData == null) {
        executeWithTryCatch(getMaxUCloudGetDomains, handleMaxUCloudGetDomains)
      }
    }

    if (loadListBoolean || items == 'list') {
      dispatch(setTeamsBundleList([]))
      const urlObj = new URL(location.href)
      urlObj.searchParams.delete('service')
      urlObj.searchParams.delete('order')
      history.replace(urlObj.href)
      getDecodeValue()
      dispatch(setIsOrderActivatedView(false))
      dispatch(setIsOrderActivatedViewByMaxCloud(false))
    }
    dispatch(setPendingOrderStatus(items?.orderStatus ?? 0))
    dispatch(resetRevService())
    if (items) {
      const values = items.productCode || items
      const isIdAsSipAddress = typeof items?.id === 'number' ? true : false
      const sipAddressId =
        items !== 'list'
          ? isIdAsSipAddress
            ? items.id
            : items.sipAddressId
          : 0
      dispatch(setShowWizard(values))
      dispatch(setShowWizardName(items))
      dispatch(setSelectedOrderService(items))
      dispatch(setSelectedServiceProducts([]))

      if (
        items?.productCode == PRODUCT_CODES.directRouting &&
        items?.orderStatus === ORDER_STATUS_ENUM.Pending
      ) {
        localStorage.removeItem('OrderActivated')
      }
      // show/hide all button actions in service pages.
      dispatch(
        setIsShowActions(
          [ORDER_STATUS_ENUM.Completed, ORDER_STATUS_ENUM.Active].includes(
            items?.orderStatus
          )
        )
      )
      if (sipAddressId === 0) {
        dispatch(resetSipTrunk())
        dispatch(resetServiceAddress())
      }
      dispatch(setSipAddressId(parseInt(items == 'list' ? 0 : sipAddressId))) // global sip address id
      renderServiceProducts(values)
    }
  }

  const getMaxUCloudGetDomains = async () => {
    const response = await api_MaxUCloudGetDomains(
      commonState?.currentCompanyDetails?.id
    )
    if (response.status === 200) {
      dispatch(setDomainData(response?.data?.Result))
    }
  }

  const handleMaxUCloudGetDomains = (error) => {
    toast.showToast(normalizeApiResponse(error)?.message, 'error')
  }

  const renderServiceProducts = (wizard) => {
    switch (wizard) {
      case SERVICE_PRODUCT_CODES.MaxCloudCoreView:
        return (
          <Suspense
            fallback={
              <div>
                <SkeletonTableLoader></SkeletonTableLoader>
              </div>
            }
          >
            <Grid item xs={12}>
              <div
                style={{ padding: '10px 0', borderBottom: '1px solid silver' }}
              >
                <Tooltip title="Close">
                  <IconButton
                    aria-label="back"
                    color="error"
                    size="large"
                    style={{
                      marginLeft: '2px',
                      marginTop: '-10px',
                      float: 'right',
                      borderRadius: 0,
                    }}
                    onClick={() => goToServiceFunction('list')}
                  >
                    <CancelIcon color="error" size="large"></CancelIcon>
                  </IconButton>
                </Tooltip>
                <span className="services-header-font">
                  {' '}
                  <span style={{ fontWeight: '600' }}>
                    {state.showWizardName?.name}
                  </span>
                </span>
              </div>
              <MaxCloudUC
                getDecodeValue={getDecodeValue}
                setLoadListBoolean={setLoadListBoolean}
                showWizardName={state.showWizardName}
                goToServiceFunction={goToServiceFunction}
              />
            </Grid>
          </Suspense>
        )
      case SERVICE_PRODUCT_CODES.DirectRouting:
        return (
          <Suspense
            fallback={
              <div>
                <SkeletonTableLoader></SkeletonTableLoader>
              </div>
            }
          >
            <Grid item xs={12}>
              <div
                style={{ padding: '10px 0', borderBottom: '1px solid silver' }}
              >
                <Tooltip title="Close">
                  <IconButton
                    aria-label="back"
                    color="error"
                    size="large"
                    style={{
                      marginLeft: '2px',
                      marginTop: '-10px',
                      float: 'right',
                      borderRadius: 0,
                    }}
                    onClick={() => goToServiceFunction('list')}
                  >
                    <CancelIcon color="error" size="large"></CancelIcon>
                  </IconButton>
                </Tooltip>
                <span className="services-header-font">
                  {' '}
                  <span style={{ fontWeight: '600' }}>{PRODUCT_NAMES.TPS}</span>
                </span>
              </div>
              <DirectRoutingPage
                getDecodeValue={getDecodeValue}
                setLoadListBoolean={setLoadListBoolean}
                showWizardName={state.showWizardName}
                goToServiceFunction={goToServiceFunction}
              />
            </Grid>
          </Suspense>
        )
      case SERVICE_PRODUCT_CODES.SipTrunking:
        return (
          <Suspense
            fallback={
              <div>
                <SkeletonTableLoader></SkeletonTableLoader>
              </div>
            }
          >
            <Grid item xs={12}>
              <div
                style={{ padding: '10px 0', borderBottom: '1px solid silver' }}
              >
                <Tooltip title="Close">
                  <IconButton
                    aria-label="back"
                    color="error"
                    size="large"
                    style={{
                      marginLeft: '2px',
                      marginTop: '-10px',
                      float: 'right',
                      borderRadius: 0,
                    }}
                    onClick={() => goToServiceFunction('list')}
                  >
                    <CancelIcon color="error" size="large"></CancelIcon>
                  </IconButton>
                </Tooltip>
                <span className="services-header-font">
                  {' '}
                  <span style={{ fontWeight: '600' }}>
                    {state.showWizardName?.name}
                  </span>
                </span>
              </div>
              <SIPTrunk
                getDecodeValue={getDecodeValue}
                setLoadListBoolean={setLoadListBoolean}
                showWizardName={state.showWizardName}
                service={services?.find(
                  (o) => o.productCode === PRODUCT_CODES.sipTrunking
                )}
              />
            </Grid>
          </Suspense>
        )
    }
  }

  const getSubscribedServices = async (
    allServices,
    orderService,
    existAddress
  ) => {
    const _subscribedServices = []
    const _subscribedStates = []
    await orderService
      ?.filter((x) =>
        [
          ORDER_STATUS_ENUM.InProgress,
          ORDER_STATUS_ENUM.Completed,
          ORDER_STATUS_ENUM.Pending,
        ].includes(x.status)
      )
      .forEach((service, index) => {
        const product = allServices?.find(
          (o) => o.productCode === service.productCode
        )
        const _service = {
          ordered: true,
          id: service.orderId,
          orderId: service.orderId,
          state: service.state,
          name: product?.name || '',
          productCode: service.productCode,
          orderStatus: service.status,
          orderTypeId: service.orderTypeId,
          sipAddressId: service.sipAddressId,
          sipAddressName: service.sipAddressName || '',
        }
        if (_service.productCode === PRODUCT_CODES.sipTrunking) {
          _subscribedServices.push({
            ..._service,
            name: `SIP Trunking_${_service.sipAddressName}`,
          })
          _subscribedStates.push(_service.sipAddressName)
        } else {
          _subscribedServices.push(_service)
        }
      })
    return _subscribedServices
  }

  const getAvailableServices = async (
    allServices,
    allProducts,
    existingAddress,
    orderService,
    maxSipLocation
  ) => {
    let _availableServices
    const _drafted = []
    const _savedOrders = []

    //draft
    if (existingAddress?.length) {
      await existingAddress?.forEach((address) => {
        if (
          [ORDER_STATUS_ENUM.InProgress, ORDER_STATUS_ENUM.Completed].includes(
            address?.OrderStatus
          )
        ) {
          _savedOrders.push({
            sipAddressId: address.SipAddressEntity.Id,
            name: `SIP Trunking_${address?.SipAddressEntity?.SipAddressName}`,
            description: '',
            iconUrl: '',
            productCode: PRODUCT_CODES.sipTrunking,
            orderStatus: address?.OrderStatus,
            ordered: false,
          })
        } else {
          _drafted.push({
            sipAddressId: address.SipAddressEntity.Id,
            name: `SIP Trunking_${address.SipAddressEntity.SipAddressName}`,
            description: '',
            iconUrl: '',
            productCode: PRODUCT_CODES.sipTrunking,
            orderStatus: address?.OrderStatus,
            ordered: false,
          })
        }
      })
    }

    _availableServices = _drafted?.length ? _drafted : []

    if (orderService?.length) {
      orderService
        .filter(
          (x) =>
            ![
              ORDER_STATUS_ENUM.InProgress,
              ORDER_STATUS_ENUM.Completed,
              ORDER_STATUS_ENUM.Pending,
            ].includes(x.status)
        )
        .forEach((service) => {
          const product = allServices?.find(
            (o) => o.productCode === service.productCode
          )
          const _service = {
            ordered: true,
            id: service.orderId,
            orderId: service.orderId,
            state: service.state,
            name: product?.name || '',
            productCode: service.productCode,
            orderStatus: service.status,
            orderTypeId: service.orderTypeId,
            sipAddressId: service.sipAddressId,
            sipAddressName: service.sipAddressName || '',
          }
          _availableServices.push(_service)
        })
    }

    // check drafted and subscribed SIP max locations to add new available service
    const isValidSipMaxLocation =
      [..._savedOrders, ..._drafted]?.length < maxSipLocation &&
      !_drafted?.length

    await removeDuplicates(allServices).forEach((service, index) => {
      if (
        !service?.ordered &&
        service.productCode &&
        service.productCode !== PRODUCT_CODES.sipTrunking &&
        ![ORDER_STATUS_ENUM.InProgress, ORDER_STATUS_ENUM.Completed].includes(
          service.orderStatus
        )
      )
        _availableServices.push(service)
      else {
        if (
          service.productCode === PRODUCT_CODES.sipTrunking &&
          isValidSipMaxLocation
        ) {
          _availableServices.push({
            id: service.id,
            name: 'SIP Trunking',
            description: service.description,
            iconUrl: service?.iconUrl || '',
            productCode: PRODUCT_CODES.sipTrunking,
            sipAddressId: 0,
            ordered: false,
          })
        } else if (
          !service?.ordered &&
          ![ORDER_STATUS_ENUM.InProgress, ORDER_STATUS_ENUM.Completed].includes(
            service.orderStatus
          ) &&
          service.orderStatus
        ) {
          _availableServices.push(service)
        }
      }
    })
    return _availableServices
  }

  const OrderActivation = () => {
    switch (ProductCode) {
      case PRODUCT_CODES.maxCloudUC:
        return (
          <OrderActivatedViewByMaxCloudUc
            goToServiceFunction={goToServiceFunction}
          />
        )
      case PRODUCT_CODES.directRouting:
        return <OrderActivatedView goToServiceFunction={goToServiceFunction} />
      default:
        break
    }
  }

  //render
  return state.isOrderActivatedView || state.isOrderActivatedViewByMaxCloud ? (
    OrderActivation()
  ) : (
    <>
      {' '}
      {loader ? (
        <SkeletonTableLoader value={4} />
      ) : (
        <ServiceReduxContext.Provider value={{ dispatch, state }}>
          <Container maxWidth="xl">
            <Grid container maxWidth="xl">
              {state.showWizard !== 'list' ? (
                <Grid item xs={12}>
                  {renderServiceProducts(state.showWizard)}
                </Grid>
              ) : (
                <>
                  {services?.length > 0 ? (
                    <>
                      {!loader && subscribedServices?.length > 0 ? (
                        <h3>Subscribed Services</h3>
                      ) : (
                        ''
                      )}
                      <Grid item xs={12}>
                        {
                          <ServiceProductCard
                            data={subscribedServices}
                            ordered={true}
                            goToServiceFunction={goToServiceFunction}
                            name="subscribed"
                          />
                        }
                      </Grid>
                      {!loader && availableServices?.length > 0 ? (
                        <h3>Available Services</h3>
                      ) : (
                        ''
                      )}
                      <Grid item xs={12}>
                        {
                          <ServiceProductCard
                            data={availableServices}
                            ordered={false}
                            goToServiceFunction={goToServiceFunction}
                          />
                        }
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        {services != null && services?.length == 0 && (
                          <NoDataFoundCard
                            messages="No Available Services"
                            subMessages={'Please Contact Administrator'}
                          />
                        )}
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Container>
        </ServiceReduxContext.Provider>
      )}
    </>
  )
}

export default ServicesProduct
