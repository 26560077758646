import { Autocomplete, TextField } from '@mui/material'
import React from 'react'

const Dropdown = ({
  options,
  getOptionLabel,
  fullWidth,
  onChange,
  defaultValue,
  disabled,
  error,
  errorMessage,
  isOptionEqualToValue = (opt, val) => opt.value === val.value,
  placeHolder,
  name,
  value,
}) => {
  return (
    <Autocomplete
      options={options}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      defaultValue={defaultValue}
      onChange={onChange}
      size="small"
      fullWidth={fullWidth}
      value={value}
      renderInput={(params) => (
        <TextField
          name={name}
          placeholder={placeHolder}
          {...params}
          error={error}
          helperText={
            error && <span style={{ color: 'red' }}>{errorMessage}</span>
          }
        />
      )}
    />
  )
}

export default Dropdown
