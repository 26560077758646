import { fetch, downloadPDF } from '../utils/httpUtil'

export const getAllServiceProvider = () => fetch(`ServiceProvider/all`)
export const getAltigenCustomers = () => fetch(`Customer/altigen`)
export const getAllcustomers = (data) => fetch(`Customer/all?parentId=${data}`)
export const getAllResellers = (data) =>
  fetch(`Reseller/all?serviceProviderId=${data}`)

// get profile
export const getProfile = (data) => fetch(`Account/profile/${data}`)

export const getCompanyProfile = (type, id = '') => {
  const urlMap = {
    master: 'Master/details',
    serviceprovider: `ServiceProvider/${id}/details`,
    reseller: `Reseller/${id}/details`,
    customer: `Customer/${id}/details`,
    salesrep: `SalesRep/${id}/details`,
  }

  return fetch(urlMap[type])
}

// GET Countries & States List
export const companyDraftStateListApi = (params) =>
  fetch(`CompanyDraft/states`, params)

//get countries
export const getCountries = () => fetch(`CompanyDraft/countries`)

//get states
export const getStates = (data) =>
  fetch(`CompanyDraft/states?countryCode=${data}`)

// get company draft by id
export const saveforlatercompanydraft = (data) => fetch(`CompanyDraft/${data}`)

// get sp approval details from id
export const getspdetailsApproval = (customerID) =>
  fetch(`ServiceProvider/${customerID}/approval`)

// get reseller approval details from id
export const getresellerApproval = (customerID) =>
  fetch(`Reseller/${customerID}/approval`)

// get customer approval details from id
export const getcustomerApproval = (customerID) =>
  fetch(`Customer/${customerID}/approval`)

// get reseller approval details from id
//export const getresellerdetailsApproval = () => fetch(`${ApiRoot}`)

// get company for stepper form
export const getCompanyNameStepper = (companyid) =>
  fetch(`CompanyDraft/${companyid}`)

// navigation api
export const getNavigation = (companyid) =>
  fetch(`Account/companydropdownmenu/${companyid}`)

//users permission list api
export const getPermissionList = () => fetch(`Account/user/permissions`)

// ServiceProvider Details
export const genericDetailsApi = (id, locationRoute) =>
  fetch(`${locationRoute}/${id}/details`)

//Generic Products API
export const genericProductsAllApi = (id, locationRoute) =>
  fetch(`${locationRoute}/${id}/product/all`)

// Get Customer Configuration
export const getCustomerConfiguration = (id) =>
  fetch(`Customer/${id}/configuration`)

// GET Billing & Invoice Details
export const invoiceBillingListGet = (id) => fetch(`Billing/list/${id}`)
export const invoiceBillingDetailsDownload = (id) =>
  downloadPDF(`Billing/downloadpdf/${id}`)

export const getServiceCancellations = (companyid) => fetch(`SalesRep/${companyid}/Cancellations`)

//Deployment Status
export const orderDeploymentStatus = (params) => fetch(`GenericAllProduct/OrderDeploymentStatus?companyId=${params.cid}&orderId=${params.oid}`)

