import React, { useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { useHomeRedux } from '../../../../../Home'
import {
  serviceSliceState,
  setIsDisable,
} from '../../../../../../../store/slice/serviceSlice'
import NumberField from '../../../../../../../common/components/numberfield/Numberfield'
import { useSelector } from 'react-redux'

const RecordingSettings = ({
  formData = {},
  handleChange,
  handleUpdate,
  errors,
  formMode,
}) => {
  const { dispatch } = useHomeRedux()
  const serviceState = useSelector(serviceSliceState)

  const recordingSizeOrder =
    (serviceState?.summaryDetails?.RevioOrderInfo?.find(
      (order) => order.Description === 'MaxCloud UC2 Call Recording'
    )?.Quantity +
      serviceState?.summaryDetails?.RevioOrderInfo?.find(
        (order) =>
          order.Description === 'MaxCloud UC2 Call Recording Additional Storage'
      )?.Quantity) *
    20
  let getRecordValue = recordingSizeOrder
    ? recordingSizeOrder
    : (serviceState?.domainData?.['max-recording-size-gb'] ?? 0) / 1000 / 1000

  let formModeCallRecordValue =
    getRecordValue > 0 || serviceState?.domainData?.['enable-call-recording']
  // new update

  return (
    <>
      <Grid container spacing={3} sx={{ mb: 2 }}>
        {/* 1) Do you want to call recording? */}
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">
              Do you want to call recording?
            </FormLabel>
            <RadioGroup
              row
              name="enable-call-recording"
              value={Boolean(formData?.['enable-call-recording'])}
              onChange={(e) => {
                const isYesSelected = e.target.value === 'true'

                handleChange({
                  target: {
                    name: 'enable-call-recording',
                    value: isYesSelected,
                  },
                })
                handleChange({
                  target: {
                    name: 'SizeLimit',
                    value: isYesSelected ? 20 : 0,
                  },
                })
                handleChange({
                  target: {
                    name: 'AgeLimit',
                    value: isYesSelected ? 0 : 0,
                  },
                })
                handleChange({
                  target: {
                    name: 'expand-storage',
                    value: isYesSelected ? formData?.['expand-storage'] : false,
                  },
                })
                handleChange({
                  target: {
                    name: 'enable-retention-length',
                    value: isYesSelected
                      ? formData?.['enable-retention-length']
                      : false,
                  },
                })
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio disabled={formModeCallRecordValue} />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={<Radio disabled={formModeCallRecordValue} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {/* 3) Max Recording Size (GB) & Max Recording Length (Days) */}

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <Typography>Max Recording Size (GB)</Typography>
            <NumberField
              name="SizeLimit"
              disabled={formData?.['expand-storage'] === false}
              value={formData?.['SizeLimit']}
              onChange={handleChange}
              disabledTextField
              step={20}
              decrement={
                Number(getRecordValue) === 0 ? 20 : Number(getRecordValue)
              }
              fullWidth
              size="small"
              type="number"
            />
            <FormHelperText>$40/month for every additional 20GB</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          {/* 4) Do you want to expand your recording storage drive? */}
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">
              Do you want to expand your recording storage drive?
            </FormLabel>
            <RadioGroup
              row
              name="expand-storage"
              value={Boolean(formData?.['expand-storage'])}
              onChange={(e) => {
                const isExpanded = e.target.value == 'true'
                handleChange({
                  target: {
                    name: 'expand-storage',
                    value: isExpanded,
                  },
                })
                handleChange({
                  target: {
                    name: 'SizeLimit',
                    value: !isExpanded ? 20 : formData?.['SizeLimit'],
                  },
                })
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio disabled={Number(getRecordValue) > 20} />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={<Radio disabled={Number(getRecordValue) > 20} />}
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {/* 2) Would you like to set 'Max Retention Length'? */}
        <Grid item xs={12} sm={6}>
          <FormControl component="fieldset" fullWidth margin="normal">
            <FormLabel component="legend">
              Would you like to set &lsquo;Max Retention Length&rsquo;?
            </FormLabel>
            <RadioGroup
              row
              name="enable-retention-length"
              value={Boolean(formData?.['enable-retention-length'])}
              onChange={(e) => {
                const isYesSelected = e.target.value === 'true'
                handleChange({
                  target: {
                    name: 'enable-retention-length',
                    value: isYesSelected,
                  },
                })
                handleChange({
                  target: {
                    name: 'AgeLimit',
                    value: !isYesSelected ? 0 : formData?.['AgeLimit'],
                  },
                })
              }}
            >
              <FormControlLabel
                value={true}
                control={
                  <Radio
                    disabled={
                      serviceState?.domainData?.['max-retention-days'] > 0
                        ? false
                        : !formData?.['enable-call-recording']
                    }
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={
                  <Radio
                    disabled={
                      serviceState?.domainData?.['max-retention-days'] > 0
                        ? false
                        : !formData?.['enable-call-recording']
                    }
                  />
                }
                label="No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <Typography>
              Max Recording Length (Days){' '}
              {formData?.['enable-retention-length'] && (
                <span style={{ color: 'red' }}>*</span>
              )}
            </Typography>
            <TextField
              name="AgeLimit"
              value={formData?.AgeLimit || ''}
              onChange={handleChange}
              fullWidth
              size="small"
              type="number"
              placeholder="00"
              disabled={!formData?.['enable-retention-length']}
              error={!!errors?.['AgeLimit']}
              helperText={errors?.['AgeLimit'] || ''}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default RecordingSettings
