import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material'
import { Box } from '@mui/material'

const NumberField = ({
  value = '',
  onChange,
  error,
  name,
  errorMessage,
  maxDigits = 10,
  decimalPlaces,
  step, // Show increment/decrement only if step is provided
  allowNegative = false, // Allow negative values (default: false)
  disabled,
  disabledTextField = false,
  decrement = 0,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value)

  // Sync state when value changes externally
  useEffect(() => {
    setInputValue(value)
  }, [value])

  const formatNumber = (value) => {
    value = value.replace(/[^0-9.-]/g, '') // Allow numbers, decimals, and minus sign

    // Prevent multiple decimals
    const parts = value.split('.')
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('')
    }

    // Limit decimal places
    if (parts.length === 2 && parts[1].length > decimalPlaces) {
      value = parts[0] + '.' + parts[1].substring(0, decimalPlaces)
    }

    // Remove leading zeros (except "0.")
    if (value.length > 1 && value.startsWith('0') && value[1] !== '.') {
      value = value.replace(/^0+/, '')
    }

    // Ensure negative sign is only at the beginning
    if (allowNegative && value.includes('-')) {
      value = '-' + value.replace(/-/g, '') // Remove extra dashes
    } else {
      value = value.replace(/-/g, '') // Remove all dashes if negative values are not allowed
    }

    // Limit total number of digits (excluding decimal point)
    const totalDigits = value.replace(/[^0-9]/g, '').length
    if (totalDigits > maxDigits) {
      return inputValue // Keep previous valid value
    }

    return value
  }

  const handleChange = (event) => {
    let newValue = formatNumber(event.target.value)
    setInputValue(newValue)
    onChange && onChange({ target: { value: newValue, name } })
  }

  const handleIncrement = () => {
    let newValue = (parseFloat(inputValue || 0) + step).toFixed(decimalPlaces)
    setInputValue(newValue)
    onChange && onChange({ target: { value: newValue, name } })
  }

  const handleDecrement = () => {
    let newValue = (parseFloat(inputValue || decrement) - step).toFixed(decimalPlaces)

    // Prevent negative values if not allowed
    if (!allowNegative && newValue < decrement ) newValue = decrement

    setInputValue(newValue)
    onChange && onChange({ target: { value: newValue, name } })
  }

  return (
    <TextField
      {...props}
      disabled={disabled}
      type="text"
      inputMode="decimal"
      value={inputValue}
      onChange={handleChange}
      error={error}
      helperText={error ? errorMessage : ''}
      InputProps={{
        readOnly: disabledTextField,
        endAdornment:
          step && !disabled ? (
            <InputAdornment position="end">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'absolute',
                  right: 5,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  background: 'white',
                  height: 40,
                }}
              >
                <IconButton
                  onClick={handleIncrement}
                  size="small"
                  sx={{ p: 0 }}
                >
                  <ArrowDropUp fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={handleDecrement}
                  size="small"
                  sx={{ p: 0 }}
                >
                  <ArrowDropDown fontSize="small" />
                </IconButton>
              </Box>
            </InputAdornment>
          ) : null, // Only show buttons if step is provided
      }}
    />
  )
}

export default NumberField
