import {
  Container,
  Typography,
  Button,
  Grid,
  Tooltip,
  IconButton,
  Card,
  CardMedia,
  CardContent,
} from '@mui/material'
import { PRODUCT_NAMES } from '../../../../../helpers/Constants'
import CancelIcon from '@mui/icons-material/Cancel'
import successCheckbox from '../../../../../assets/checkbox_tick.png'
import DomainCreationModal from '../stepper-actions/domain-creation/DomainCreationModal'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {setConfiguration } from '../../../../../store/slice/serviceSlice'
import { getCustomerConfiguration } from '../../../../../api/get/getApi'
import { normalizeApiResponse } from '../../../../../helpers/HelperFunctions'
import { useHomeRedux } from '../../../Home'
import jwt_decode from 'jwt-decode'
import Toast from '../../../../../common/components/mui/Toast'
import { setCompanyId } from '../../../../../store/slice/homeSlice'


export default function OrderActivatedViewByMaxCloudUc(props) {
  const [openModal, setOpenModal] = useState(false)
    const [searchParams] = useSearchParams()
    const { dispatch } = useHomeRedux()  

  const handleClose = () => setOpenModal(false)

  const getDecodeValue = () => {
    const decodeToken = localStorage.getItem('sd_cp.jwt:tkn')
    const decoded = jwt_decode(decodeToken)
    if (decoded) {
      let companyId = searchParams.get('company_id')
      if (!companyId) {
        companyId = decoded?.company_id
      }
      getCustomerConfigApi(companyId)
      dispatch(setCompanyId(companyId))
    }
  }
  
  useEffect(() => {
    getDecodeValue()
  }, [])
    const getCustomerConfigApi = (id) => {
      if (id) {
        getCustomerConfiguration(id)
          .then((response) => {
            if (response.data.result) {
              dispatch(setConfiguration(response.data.result))
            }
          })
          .catch((error) => {
            Toast.showToast(normalizeApiResponse(error)?.message, 'error')
          })
      }
    }

  return (
    <Grid item xs={12}>
      <div style={{ padding: '10px 0', borderBottom: '1px solid silver' }}>
        <Tooltip title="Close">
          <IconButton
            aria-label="back"
            color="error"
            size="large"
            style={{
              marginLeft: '2px',
              marginTop: '-10px',
              float: 'right',
              borderRadius: 0,
            }}
            onClick={() => props?.goToServiceFunction('list')}
          >
            <CancelIcon color="error" size="large"></CancelIcon>
          </IconButton>
        </Tooltip>
        <span className="services-header-font">
          {' '}
          <span style={{ fontWeight: '600' }}>{PRODUCT_NAMES.MAXCLOUD}</span>
        </span>
      </div>
      <Container maxWidth="xl">
        <Card
          style={{ marginTop: '20px', padding: '10px', textAlign: 'center' }}
        >
          <CardMedia>
            <img
              src={successCheckbox}
              style={{
                marginTop: '30px',
                maxWidth: '120px',
                maxHeight: '120px',
              }}
            />
          </CardMedia>
          <CardContent style={{}}>
            <Typography
              variant="h5"
              component="h5"
              style={{ margin: '20px 0' }}
            >
              Your order has been successfully activated for this service.{' '}
              <br></br> To continue with the create domain, please provide your
              details by clicking the ‘Create New Domain’ button.
            </Typography>

            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                marginTop: '20px',
              }}
            >
              <Button variant="contained" onClick={() => setOpenModal(true)}>
                Create New Domain
              </Button>
            </div>
          </CardContent>
        </Card>
        <DomainCreationModal open={openModal} handleClose={handleClose}  goToServiceFunction = { props?.goToServiceFunction}/>
      </Container>
    </Grid>
  )
}
